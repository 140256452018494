import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Loader from './Loader';

const StepFive = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isOtherIncomesSubmitted, setIsOtherIncomesSubmitted] = useState(false);
    const [otherIncomes, setOtherIncomes] = useState({
        source: "",
        monthlyIncome: ""
    })
    const [addOtherIncomes, setAddOtherIncomes] = useState(true);
    const [editOtherIncomes, setEditOtherIncomes] = useState(false);
    const [data, setData] = useState({
        incomes: [],
        isOtherIncome: "Yes",
        bankList: [],
        creditAcctsList: [],
        creditAcctsLoans: true
    });
    const [bankData, setBankData] = useState({
        curentBank: "",
        city: '',
        state: '',
        accountType: 'Checking',
        otherAccountType: ''
    })
    const [addBankData, setAddBankData] = useState(false);
    const [editBankData, setEditBankData] = useState(false);
    const [isBankDataSubmitted, setIsBankDataSubmitted] = useState(false);

    const [creditAcctsData, setCreditAcctsData] = useState({
        typeOfAccount: "",
        name: "",
        amountOwed: "",
        monthlyPayment: ""
    })
    const [addCreditAcctsData, setAddCreditAcctsData] = useState(false);
    const [editCreditAcctsData, setEditCreditAcctsData] = useState(false);
    const [isCreditAcctsDataSubmitted, setIsCreditAcctsDataSubmitted] = useState(false);
    const [isAnyError, setIsAnyError] = useState({ value: false, isCheck: false })

    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
    }, [])

    useEffect(() => {
        if(!data.creditAcctsLoans){
            setAddCreditAcctsData(false)
            setEditCreditAcctsData(false)
        }
        if(isAnyError.isCheck) {
            if(data.bankList.length === 0 || (data.creditAcctsLoans && data.creditAcctsList.length === 0) || (data.incomes.length === 0 && data.isOtherIncome === "Yes")) {
                setIsAnyError({ value: true, isCheck: true })
            }
            else setIsAnyError({ value: false, isCheck: true })
        }
    }, [data])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 5,
            stepData: data,
        }
        return dataObj;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)

        if(data.bankList.length === 0 || (data.creditAcctsLoans && data.creditAcctsList.length === 0) || (data.incomes.length === 0 && data.isOtherIncome === "Yes")) {
            setIsAnyError({ value: true, isCheck: true })
            return
        }
        await setMainData(5, data)
        setActiveStep(6)
    }

    const handleOtherIncomeSave = () => {
        setIsOtherIncomesSubmitted(true)
        if(!otherIncomes.source || !otherIncomes.monthlyIncome) {
            return
        }
        setData({ ...data, incomes: [...data.incomes, otherIncomes] })
        setOtherIncomes({ source: "", monthlyIncome: "" })
        setAddOtherIncomes(false)
        setEditOtherIncomes(false)
        setIsOtherIncomesSubmitted(false)
    }

    const handleEdit = (item, i) => {
        setData({ ...data, incomes: data.incomes.filter((item, index) => index !== i) })
        setOtherIncomes(item)
        setAddOtherIncomes(false)
        setEditOtherIncomes(true)
    }

    const handleDelete = (index) => {
        if(data.incomes.length === 1) {
            setAddOtherIncomes(true)
        }
        setData({ ...data, incomes: data.incomes.filter((item, i) => index !== i) })
    }

    const handleBankDataSave = () => {
        setIsBankDataSubmitted(true)
        if(!bankData.curentBank || !bankData.city || !bankData.state || (bankData.accountType === "Other" && !bankData.otherAccountType)) {
            return
        }
        setData({ ...data, bankList: [...data.bankList, bankData] })
        setBankData({ curentBank: "", city: '', state: '', accountType: 'Checking', otherAccountType: '' })
        setAddBankData(false)
        setEditBankData(false)
        setIsBankDataSubmitted(false)
    }

    const handleBankDataEdit = (item, i) => {
        setData({ ...data, bankList: data.bankList.filter((item, index) => index !== i) })
        setBankData(item)
        setAddBankData(false)
        setEditBankData(true)
    }

    const handleBankDataDelete = (index) => {
        if(data.bankList.length === 1) {
            setAddBankData(true)
        }
        setData({ ...data, bankList: data.bankList.filter((item, i) => index !== i) })
    }

    const handleCreditAcctsDataSave = () => {
        setIsCreditAcctsDataSubmitted(true)
        if(!creditAcctsData.typeOfAccount || !creditAcctsData.name || !creditAcctsData.amountOwed || !creditAcctsData.monthlyPayment) {
            return
        }
        setData({ ...data, creditAcctsList: [...data.creditAcctsList, creditAcctsData] })
        setCreditAcctsData({ typeOfAccount: "", name: "", amountOwed: "", monthlyPayment: "" })
        setAddCreditAcctsData(false)
        setEditCreditAcctsData(false)
        setIsCreditAcctsDataSubmitted(false)
    }

    const handleCreditAcctsDataEdit = (item, i) => {
        setData({ ...data, creditAcctsList: data.creditAcctsList.filter((item, index) => index !== i) })
        setCreditAcctsData(item)
        setAddCreditAcctsData(false)
        setEditCreditAcctsData(true)
    }

    const handleCreditAcctsDataDelete = (index) => {
        if(data.creditAcctsList.length === 1) {
            setAddCreditAcctsData(true)
        }
        setData({ ...data, creditAcctsList: data.creditAcctsList.filter((item, i) => index !== i) })
    }

    return (
        <>
            <section className='step-section step-5'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-3'>Other Income</h3>
                <p className='fs-16 mb-1' style={{ fontWeight: '500' }}>Are there other sources of income you would like to include?</p>
                <p className='mb-1'>Such as social security, child support, alimony, housing assistance, pension, etc.</p>
                <Form onSubmit={handleNext}>
                    <Form.Check
                        inline
                        label="Yes"
                        name="otherIncome"
                        type='radio'
                        id="otherIncomeYes"
                        checked={data.isOtherIncome === 'Yes'}
                        onChange={() => setData({ ...data, isOtherIncome: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="otherIncome"
                        type="radio"
                        id="otherIncomeno"
                        checked={data.isOtherIncome === 'No'}
                        onChange={() => setData({ ...data, isOtherIncome: 'No' })}
                    />
                    {data.isOtherIncome === "Yes" &&
                        <>
                            {data.incomes.map((item, index) =>
                                <div key={index} className='applying-item'>
                                    <div>
                                        <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                            <div className='application-name'>{item.source}</div>
                                        </h4>
                                        <p className='mb-1'>
                                            <span className='box-sub-title'>Monthly Income: </span>${item.monthlyIncome}
                                        </p>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleEdit(item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete(index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {
                                (addOtherIncomes || editOtherIncomes) &&
                                <div className='applying-form position-relative'>
                                    {
                                        data.incomes.length !== 0 &&
                                        <button onClick={() => setAddOtherIncomes(false)} type='button' className='action-btn delete-form-btn'>
                                            <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </button>
                                    }
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-3'>
                                            <Form.Label>Source</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="source"
                                                value={otherIncomes.source}
                                                onChange={(e) => setOtherIncomes({ ...otherIncomes, source: e.target.value })}
                                                isInvalid={!otherIncomes.source && isOtherIncomesSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">source is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="5" className='mb-3 price-input'>
                                            <Form.Label>Monthly Income</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="monthlyIncome"
                                                value={otherIncomes.monthlyIncome}
                                                onChange={(e) => setOtherIncomes({ ...otherIncomes, monthlyIncome: e.target.value })}
                                                isInvalid={!otherIncomes.monthlyIncome && isOtherIncomesSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">monthly income is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button onClick={handleOtherIncomeSave} type="button" className='text-uppercase border-btn d-block mx-auto mt-2'>Save</Button>
                                </div>
                            }
                            {
                                !addOtherIncomes && !editOtherIncomes &&
                                <button onClick={() => { setAddOtherIncomes(true); setOtherIncomes({ source: "", monthlyIncome: "" }) }} type='button' className='action-btn'>
                                    <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                    Add Other Income Source
                                </button>
                            }
                            {isSubmitted && data.incomes.length === 0 && data.isOtherIncome === "Yes" && <div className="text-danger error-msg">Minimum 1 income source is required</div>}
                        </>
                    }
                    <h4 className="step-small-title mb-1 mt-3">Current Bank / Financial Institution</h4>
                    <p>For example, "Wells Fargo" or "Navy Federal Credit Union". If you don't have a bank account, just enter "N/A".</p>
                    <button onClick={() => setAddBankData(true)} type='button' className='action-btn mb-2'>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Bank
                    </button>
                    {data.bankList &&
                        data.bankList.map((item, index) => {
                            return (
                                <div key={index} className='applying-item'>
                                    <div>
                                        <p className="mb-1"><span className="box-sub-title">Bank: </span>{item.curentBank}</p>
                                        <p className="mb-1"><span className="box-sub-title">City: </span>{item.city}</p>
                                        <p className="mb-1"><span className="box-sub-title">State: </span>{item.state}</p>
                                        <p className="mb-1"><span className="box-sub-title">Account Type: </span>{item.accountType}</p>
                                        {item.otherAccountType && <p className="mb-1"><span className="box-sub-title">Other Account Type: </span>{item.otherAccountType}</p>}
                                        <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleBankDataEdit(item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleBankDataDelete(index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {(addBankData || editBankData) &&
                        <div className='applying-form position-relative'>
                            {
                                data.bankList.length !== 0 &&
                                <button onClick={() => { setAddBankData(false); setBankData({ curentBank: "", city: '', state: '', accountType: 'Checking', otherAccountType: '' }) }} type='button' className='action-btn delete-form-btn'>
                                    <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                </button>
                            }
                            <Row>
                                <Form.Group as={Col} md="12" className='mb-3' controlId="bankName">
                                    <Form.Label>Bank / Financial Institution Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bankName"
                                        value={bankData.curentBank}
                                        onChange={(e) => setBankData({ ...bankData, curentBank: e.target.value })}
                                        isInvalid={isBankDataSubmitted && !bankData.curentBank}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">bank name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={bankData.city}
                                        onChange={(e) => setBankData({ ...bankData, city: e.target.value })}
                                        isInvalid={isBankDataSubmitted && !bankData.city}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">city is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        value={bankData.state}
                                        onChange={(e) => setBankData({ ...bankData, state: e.target.value })}
                                        isInvalid={isBankDataSubmitted && !bankData.state}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">state is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className='mb-3 col-md-6' controlId='accountType'>
                                    <Form.Label>Account Type</Form.Label>
                                    <Form.Select
                                        name="accountType"
                                        value={bankData.accountType}
                                        onChange={(e) => setBankData({ ...bankData, accountType: e.target.value })}
                                        isInvalid={isBankDataSubmitted && !bankData.accountType}
                                    >
                                        <option value="Checking">Checking</option>
                                        <option value="Savings">Savings</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">account type is required</Form.Control.Feedback>
                                </Form.Group>

                                {bankData.accountType === "Other" &&
                                    <Form.Group as={Col} md="12" className='mb-3' controlId="otherAccountType">
                                        <Form.Label>Other Account Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="otherAccountType"
                                            value={bankData.otherAccountType}
                                            onChange={(e) => setBankData({ ...bankData, otherAccountType: e.target.value })}
                                            isInvalid={isBankDataSubmitted && !bankData.otherAccountType}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">other account type is required</Form.Control.Feedback>
                                    </Form.Group>
                                }
                            </Row>
                            <Button onClick={handleBankDataSave} type="button" className='text-uppercase border-btn d-block mx-auto mt-2'>Save</Button>
                        </div>
                    }
                    {isSubmitted && data.bankList.length === 0 && <div className="text-danger error-msg">Minimum 1 bank details is required</div>}

                    <h4 className="step-small-title mb-1 mt-3">Credit Accts & Loans</h4>
                    {
                        !data?.creditAcctsList?.length && <Form.Check
                            type='checkbox'
                            id="creditAcctsLoans"
                            label="Not applicable"
                            checked={!data.creditAcctsLoans}
                            onChange={(e) => setData({ ...data, creditAcctsLoans: !e.target.checked })}
                            className='mb-2'
                        />
                    }
                    {data?.creditAcctsLoans && <button onClick={() => setAddCreditAcctsData(true)} type='button' className='action-btn mb-2'>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Credit Accts & Loans
                    </button>}
                    {data.creditAcctsList &&
                        data.creditAcctsList.map((item, index) => {
                            return (
                                <div key={index} className='applying-item'>
                                    <div>
                                        <p className="mb-1"><span className="box-sub-title">Type Of Account: </span>{item.typeOfAccount}</p>
                                        <p className="mb-1"><span className="box-sub-title">Issuing Bank: </span>{item.name}</p>
                                        <p className="mb-1"><span className="box-sub-title">Amount Owed: </span>{item.amountOwed}</p>
                                        <p className="mb-1"><span className="box-sub-title">Monthly Payment: </span>{item.monthlyPayment}</p>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleCreditAcctsDataEdit(item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleCreditAcctsDataDelete(index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {(addCreditAcctsData || editCreditAcctsData) &&
                        <div className='applying-form position-relative'>
                            {
                                data.bankList.length !== 0 &&
                                <button onClick={() => { setAddCreditAcctsData(false); setCreditAcctsData({ typeOfAccount: "", name: "", amountOwed: "", monthlyPayment: "" }) }} type='button' className='action-btn delete-form-btn'>
                                    <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                </button>
                            }
                            <Row>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="typeOfAccount">
                                    <Form.Label>Type of account</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="typeOfAccount"
                                        value={creditAcctsData.typeOfAccount}
                                        onChange={(e) => setCreditAcctsData({ ...creditAcctsData, typeOfAccount: e.target.value })}
                                        isInvalid={isCreditAcctsDataSubmitted && !creditAcctsData.typeOfAccount}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Type of account is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="name">
                                    <Form.Label>Issuing Bank</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={creditAcctsData.name}
                                        onChange={(e) => setCreditAcctsData({ ...creditAcctsData, name: e.target.value })}
                                        isInvalid={isCreditAcctsDataSubmitted && !creditAcctsData.name}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="amountOwed">
                                    <Form.Label>Amount owed</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amountOwed"
                                        value={creditAcctsData.amountOwed}
                                        onChange={(e) => setCreditAcctsData({ ...creditAcctsData, amountOwed: e.target.value })}
                                        isInvalid={isCreditAcctsDataSubmitted && !creditAcctsData.amountOwed}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Amount owed is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="monthlyPayment">
                                    <Form.Label>Monthly payment</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="monthlyPayment"
                                        value={creditAcctsData.monthlyPayment}
                                        onChange={(e) => setCreditAcctsData({ ...creditAcctsData, monthlyPayment: e.target.value })}
                                        isInvalid={isCreditAcctsDataSubmitted && !creditAcctsData.monthlyPayment}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Monthly payment is required</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button onClick={handleCreditAcctsDataSave} type="button" className='text-uppercase border-btn d-block mx-auto mt-2'>Save</Button>
                        </div>
                    }
                    {isSubmitted && data.creditAcctsLoans && data.creditAcctsList.length === 0 && <div className="text-danger error-msg">Minimum 1 credit accts & loans is required</div>}

                    <Button type="submit" className='text-uppercase step-button d-block mx-auto mt-4' disabled={isMainLoader}>{isMainLoader ? <Loader /> : 'Next'}</Button>
                    {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section>
        </>
    )
});

export default StepFive