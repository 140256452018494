import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Form, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import { UploadFunction } from '../utils/s3/uploadImage';
import { DeleteFunction } from '../utils/s3/deleteImage';
import { API } from 'aws-amplify';
import { REACT_APP_API_NAME } from '../config/aws-config';
import DatePicker from 'react-date-picker';

const StepNine = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props;
    const [loading, setLoading] = useState(false);
    const [userID, setUserID] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAnyError, setIsAnyError] = useState(false)
    const [photoIdLoading, setPhotoIdLoading] = useState(false)
    const [proofLoading, setProofLoading] = useState(false)
    const [otherProofLoading, setOtherProofLoading] = useState(false)

    const [data, setData] = useState({
        photo: [],
        incomeProof: [],
        otherDocs: [],
        isRequirementsAgreed: false,
        isQualificationsAgreed: false,
    })

    const [errors, setErrors] = useState({
        photoId: "",
        proofOfIncome: ""
    });

    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
        let user = JSON.parse(localStorage.getItem('userData'))
        setUserID(user?.id)
    }, [])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 9,
            stepData: data,
        }
        return dataObj;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)

        let error = {};
        let isError = false;
        if(!data.photo.length) {
            error = { ...error, photoId: "photo id is required" }
            isError = true
        }
        if(!data.incomeProof.length) {
            error = { ...error, proofOfIncome: "proof of income is required" }
            isError = true
        }
        if(isError) {
            setErrors(error)
            setIsAnyError(true)
            return
        }

        setLoading(true)
        let mainData = data
        mainData = { ...mainData, isRequirementsAgreed: mainData.isRequirementsAgreed, isQualificationsAgreed: mainData.isQualificationsAgreed }
        // let userData = JSON.parse(localStorage.getItem('userData'))

        // if(mainData.uploadLater) {
        //     await API.post(REACT_APP_API_NAME, "/application/send-mail", {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        //         },
        //         body: {
        //             email: userData.email,
        //             firstName: userData.firstName,
        //             url: "https://www.app.denaliarizona.com"
        //         }
        //     })
        //         .then(async (response) => {
        //         })
        //         .catch((err) => {
        //             console.log(err)
        //         })
        // }

        setData(mainData)

        setLoading(false)
        await setMainData(9, mainData)
        setActiveStep(10)
    }

    const removeImage = async (name, index) => {
        if(name === "photo") {
            const updatedImages = [...data.photo];
            const deletedItem = updatedImages.splice(index, 1)[0];
            if(typeof deletedItem === "string") {
                await DeleteFunction(deletedItem);
            }
            if(updatedImages.length === 0) {
                setIsAnyError(true)
                setErrors({ ...errors, photoId: "photo id is required" })
            }
            setData({ ...data, photo: updatedImages });

        } else if(name === 'incomeProof') {
            const updatedImages = [...data.incomeProof];
            const deletedItem = updatedImages.splice(index, 1)[0];
            if(typeof deletedItem === "string") {
                await DeleteFunction(deletedItem);
            }
            if(updatedImages.length === 0) {
                setIsAnyError(true)
                setErrors({ ...errors, proofOfIncome: "proof of income is required" })
            }
            setData({ ...data, incomeProof: updatedImages });

        } else if(name === 'otherDocs') {
            const updatedImages = [...data.otherDocs];
            const deletedItem = updatedImages.splice(index, 1)[0];
            if(typeof deletedItem === "string") {
                await DeleteFunction(deletedItem);
            }
            setData({ ...data, otherDocs: updatedImages });
        }
    }

    const deleteSignature = async () => {
        if(typeof data.signature === "string") {
            await DeleteFunction(data.signature);
        }
        setData({ ...data, signature: "" })
    }

    const handlePhotoId = async (e) => {
        setPhotoIdLoading(true)
        if(!e.target.files[0]) return
        const file = e.target.files[0];
        const fileType = file.type;
        if(!fileType.startsWith('image/')) {
            setErrors({ ...errors, photoId: "Please upload only image file." });
            return;
        }
        let path = `${userID}/${new Date().getTime()}_${file.name}`
        let imageData = await UploadFunction(file, path);
        setData({ ...data, photo: [...data.photo, imageData] })
        if(!errors.proofOfIncome) setIsAnyError(false)
        setErrors({ ...errors, photoId: "" })
        setPhotoIdLoading(false)
    }

    const handleIncomeOfProof = async (e) => {
        setProofLoading(true)
        if(!e.target.files[0]) return
        const file = e.target.files[0];
        let path = `${userID}/${new Date().getTime()}_${file.name}`
        let imageData = await UploadFunction(file, path);
        setData({ ...data, incomeProof: [...data.incomeProof, imageData] })
        if(!errors.photoId) setIsAnyError(false)
        setErrors({ ...errors, proofOfIncome: "" })
        setProofLoading(false)
    }

    const handleOtherDocs = async (e) => {
        setOtherProofLoading(true)
        if(!e.target.files[0]) return
        const file = e.target.files[0];
        let path = `${userID}/${new Date().getTime()}_${file.name}`
        let imageData = await UploadFunction(file, path);
        setData({ ...data, otherDocs: [...data.otherDocs, imageData] })
        setOtherProofLoading(false)
    }

    const getFileExtension = (url) => {
        return url.split(".").pop().toLowerCase();
    };

    return (
        <>
            <section className='step-section step-9'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-2'>Upload Documents</h3>
                <Form onSubmit={handleNext}>
                    <div className='doc-wraper mb-4'>
                        <h4 className='step-small-title mb-2 mt-3'>Photo ID</h4>
                        <p>Must be government-issued e.g. driver's license, passport, military ID, etc.</p>
                        {photoIdLoading && <p>Loading...</p>}
                        {data.photo &&
                            data.photo.map((item, i) => {
                                return (
                                    <div key={i} className='border-bottom py-3 mb-2'>
                                        <div className='d-flex align-items-center uploaded-img'>
                                            <img src={typeof item === "string" ? item : URL?.createObjectURL(item)} />
                                            <div>{typeof item === "string" ? item.substring(item.lastIndexOf("/") + 1) : item.name}</div>
                                            <div className='d-flex ms-auto'>
                                                <a href={typeof item === "string" ? item : URL?.createObjectURL(item)} target='_blank' className='d-block p-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                </a>
                                                <span className='d-block p-1 ms-1' onClick={() => removeImage('photo', i)}>
                                                    <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='d-flex position-relative'>
                            <input type='file' className='input-file' id="photo" onChange={handlePhotoId} value="" accept="image/*" />
                            <label className="action-btn" htmlFor='photo'>
                                <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                Upload Photo ID
                            </label>
                        </div>
                        {errors.photoId ? <p className="text-danger error-msg m-1">{errors.photoId}</p> : ""}
                    </div>
                    <div className='doc-wraper mb-4'>
                        <h4 className='step-small-title mb-2 mt-3'>Proof of Income</h4>
                        <p>E.g. paystub, bank statement, social security, etc.</p>
                        {proofLoading && <p>Loading...</p>}
                        {data?.incomeProof &&
                            data?.incomeProof?.map((item, i) => {
                                return (
                                    <div key={i} className='border-bottom py-3 mb-2'>
                                        <div className='d-flex align-items-center uploaded-img'>
                                            {(getFileExtension(item) === "jpg" || getFileExtension(item) === "jepg" || getFileExtension(item) === "png" || getFileExtension(item) === "svg") ?
                                                <>
                                                    <img src={item} />
                                                    <a href={item} target='_blank' className='img-name-show ps-1'>{item}</a>
                                                </>
                                                : <a href={item} target='_blank' className='img-name-show'>{item}</a>
                                            }
                                            {/* <img src={typeof item === "string" ? item : URL?.createObjectURL(item)} /> */}
                                            {/* <span className='ms-0'>{typeof item === "string" ? item.substring(item.lastIndexOf("/") + 1) : item.name}</span> */}
                                            {/* <div>{typeof item === "string" ? item.substring(item.lastIndexOf("/") + 1) : item.name}</div> */}
                                                {/* <a href={item} target='_blank' className='d-block p-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                </a> */}
                                                <span className='d-block p-1 ms-auto' onClick={() => removeImage('incomeProof', i)}>
                                                    <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='d-flex position-relative'>
                            <input type='file' className='input-file' id="incomeProof" onChange={handleIncomeOfProof} value="" />
                            <label className="action-btn" htmlFor='incomeProof'>
                                <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                Upload Proof of Income
                            </label>
                        </div>
                        {errors.proofOfIncome ? <p className="text-danger error-msg m-1">{errors.proofOfIncome}</p> : ""}
                    </div>
                    <div className='doc-wraper mb-4'>
                        <h4 className='step-small-title mb-2 mt-3'>Other Docs <span style={{ opacity: '0.8' }}>(Social Security Card)</span></h4>
                        <p>Include any other documents requested by the landlord.</p>
                        {otherProofLoading && <p>Loading...</p>}
                        {data?.otherDocs &&
                            data?.otherDocs?.map((item, i) => {
                                return (
                                    <div key={i} className='border-bottom py-3 mb-2'>
                                        <div className='d-flex align-items-center uploaded-img'>
                                            {(getFileExtension(item) === "jpg" || getFileExtension(item) === "jepg" || getFileExtension(item) === "png" || getFileExtension(item) === "svg") ?
                                                <>
                                                    <img src={item} />
                                                    <a href={item} target='_blank' className='img-name-show'>{item}</a>
                                                </>
                                                : <a href={item} target='_blank' className='img-name-show'>{item}</a>
                                            }
                                            {/* <img src={typeof item === "string" ? item : URL?.createObjectURL(item)} /> */}
                                            {/* <span className='ms-0'>{typeof item === "string" ? item.substring(item.lastIndexOf("/") + 1) : item.name}</span> */}
                                            {/* <div>{typeof item === "string" ? item.substring(item.lastIndexOf("/") + 1) : item.name}</div> */}
                                            {/* <div className='d-flex ms-auto'> */}
                                                {/* <a href={typeof item === "string" ? item : URL?.createObjectURL(item)} target='_blank' className='d-block p-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="20px" viewBox="0 0 24 24" fill="none"><g id="style=linear"><g id="eye-open"><path id="vector" d="M15 12C15 13.6592 13.6592 15 12 15C10.3408 15 9 13.6592 9 12C9 10.3408 10.3408 9 12 9C13.6592 9 15 10.3408 15 12Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path id="vector_2" d="M12 19.27C15.53 19.27 18.82 17.4413 21.11 14.2764C22.01 13.0368 22.01 10.9532 21.11 9.71356C18.82 6.54861 15.53 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C5.17997 17.4413 8.46997 19.27 12 19.27Z" stroke="#033A6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></g></g></svg>
                                                </a> */}
                                                <span className='d-block p-1 ms-1' onClick={() => removeImage('otherDocs', i)}>
                                                    <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                </span>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='d-flex position-relative'>
                            <input type='file' className='input-file' id="otherDocs" onChange={handleOtherDocs} value="" />
                            <label className="action-btn" htmlFor='otherDocs'>
                                <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                                Upload Other Documents
                            </label>
                        </div>
                    </div>
                    <p className='mt-5'>Prior to submitting, please <a target='_blank' href='https://assets-denali.s3.amazonaws.com/2024-0805+-+Applicant+Acceptance.pdf'>click here</a> to review the acceptance criteria guidelines set by Denali Real Estate, LLC to ensure you understand the necessary conditions.</p>
                    <Form.Check
                        type='checkbox'
                        id="isRequirementsAgreed"
                        label={<div>Please check here to confirm you have read the acceptance criteria guidelines should be linked to pdf new window.</div>}
                        name='isRequirementsAgreed'
                        onChange={(e) => setData({ ...data, isRequirementsAgreed: e.target.checked })}
                    />
                    <Form.Check
                        type='checkbox'
                        id="isQualificationsAgreed"
                        label={<div>I agree that I have read the <a target='_blank' href='https://denaliarizona.com/wp-content/uploads/2020/04/Qualifying-Criteria-To-Rent-Applicant-1.pdf'>list of qualifications</a> and meet all qualifications.</div>}
                        name='isQualificationsAgreed'
                        onChange={(e) => setData({ ...data, isQualificationsAgreed: e.target.checked })}
                    />
                    <Button type="submit" disabled={!(data.isRequirementsAgreed && data.isQualificationsAgreed) || (loading || isMainLoader)} className='text-uppercase step-button d-block mx-auto mt-4'>{loading || isMainLoader ? <Loader /> : 'Submit'}</Button>
                    {isAnyError && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section>
        </>
    )
});

export default StepNine