import Loader from './Loader';


const LoaderScreen = () => {

  return (
    <section className='loader-screen'>
      <div className='loader-screen-wrap'>
        <Loader />
      </div>
    </section>
  )
}

export default LoaderScreen