import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import Loader from './Loader';

const StepEight = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props

    let phone_pattern = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;

    const [data, setData] = useState({
        fullName: "",
        relationship: "",
        phoneNumber: "",
        additionalComments: "",
        reference: []
    })

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [referenceModal, setReferenceModal] = useState(false)
    const [isReferenceSubmitted, setIsReferenceSubmitted] = useState(false);
    const [editReference, setEditReference] = useState(null);
    const [isAnyError, setIsAnyError] = useState({value: false, isCheck: false})

    const [referenceData, setReferenceData] = useState({
        fullName: "",
        relationship: "",
        phoneNumber: ""
    })

    useEffect(() => {
        if (activeData) {
            setData(activeData)
        }
    }, [])

    useEffect(() => {
        if(isAnyError.isCheck) {
            if (!data.fullName || !data.relationship || (!data.phoneNumber || !phone_pattern.test(data.phoneNumber) || data.reference.length < 2)) {
                setIsAnyError({value: true, isCheck: true})
            }
            else setIsAnyError({value: false, isCheck: true})
        }
    }, [data])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 8,
            stepData: data,
        }
        return dataObj;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)
        if (!data.fullName || !data.relationship || (!data.phoneNumber || !phone_pattern.test(data.phoneNumber) || data.reference.length < 2)) {
            setIsAnyError({value: true, isCheck: true})
            return
        }
        await setMainData(8, data)
        setActiveStep(9)
    }

    const handleReferenceSave = (e) => {
        e.preventDefault()
        setIsReferenceSubmitted(true)
        if (!referenceData.fullName || !referenceData.relationship || !referenceData.phoneNumber || !phone_pattern.test(referenceData.phoneNumber)) {
            return
        }
        if (editReference !== null) {
            let updatedData = [...data.reference]
            updatedData = updatedData?.map((item, index) => {
                if (index === editReference) {
                    item = referenceData
                }
                return item
            })
            setData({ ...data, reference: updatedData })
        } else {
            setData({ ...data, reference: [...data.reference, referenceData] })
        }

        setEditReference(null)
        setReferenceModal(false)
        setReferenceData({
            fullName: "",
            relationship: "",
            phoneNumber: ""
        })
        setIsReferenceSubmitted(false)
    }

    const handleEdit = (item, i) => {
        setReferenceData(item)
        setReferenceModal(true)
        setEditReference(i)
    }

    const handleDelete = (index) => {
        setData({ ...data, reference: data.reference.filter((item, i) => index !== i) })
    }

    const handleModalClose = () => {
        setReferenceData({
            fullName: "",
            relationship: "",
            phoneNumber: ""
        })
        setEditReference(null)
        setReferenceModal(false)
    }

    return (
        <>
            <section className='step-section step-8'>
                <Modal
                    show={referenceModal}
                    onHide={handleModalClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header onClick={handleModalClose} closeButton className='border-0'></Modal.Header>
                    <Modal.Body>
                        <h3 className='step-title mb-0'>{editReference !== null ? "Edit" : "Add"} a reference</h3>
                        <Form className='mt-4' onSubmit={handleReferenceSave}>
                            <Row>
                                <Form.Group as={Col} className='mb-3' md="12" controlId="name">
                                    <Form.Label>Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={referenceData.fullName}
                                        onChange={(e) => setReferenceData({ ...referenceData, fullName: e.target.value })}
                                        isInvalid={isReferenceSubmitted && !referenceData.fullName}
                                    />
                                    <Form.Control.Feedback type="invalid">Reference name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className='mb-3' md="12" controlId="relationship">
                                    <Form.Label>Relationship</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="relationship"
                                        value={referenceData.relationship}
                                        onChange={(e) => setReferenceData({ ...referenceData, relationship: e.target.value })}
                                        isInvalid={isReferenceSubmitted && !referenceData.relationship}
                                    />
                                    <Form.Control.Feedback type="invalid">Reference name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="7" className='mb-3' controlId="referencePhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="referencePhoneNumber"
                                        placeholder="(---) --- - ----"
                                        value={referenceData.phoneNumber}
                                        onChange={(e) => {
                                            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                            const filterValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                            setReferenceData({ ...referenceData, phoneNumber: filterValue })
                                        }}
                                        isInvalid={isReferenceSubmitted && (!referenceData.phoneNumber || !phone_pattern.test(referenceData.phoneNumber))}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!referenceData.phoneNumber ? "phonenumber is required" : !phone_pattern.test(referenceData.phoneNumber) ? "phonenumber is invaild" : ""}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className='text-uppercase step-button d-block mx-auto mb-2'>{editReference !== null ? "Edit" : "Add"} Reference</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-2'>Other Information</h3>
                <p className='mb-4'>Almost there - a few more questions!</p>
                <Form onSubmit={handleNext}>
                    <h4 className="step-small-title mb-1 mt-3">Emergency Contact</h4>
                    <Row className="mt-3">
                        <Form.Group as={Col} md="10" className='mb-3' controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullName"
                                value={data.fullName}
                                onChange={(e) => setData({ ...data, fullName: e.target.value })}
                                isInvalid={isSubmitted && !data.fullName}
                            />
                            <Form.Control.Feedback type="invalid">full name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="10" className='mb-3' controlId="relationship">
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control
                                type="text"
                                name="relationship"
                                value={data.relationship}
                                onChange={(e) => setData({ ...data, relationship: e.target.value })}
                                isInvalid={isSubmitted && !data.relationship}
                            />
                            <Form.Control.Feedback type="invalid">relationship value is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="7" className='mb-3' controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="phoneNumber"
                                value={data.phoneNumber}
                                placeholder="(---) --- - ----"
                                onChange={(e) => {
                                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                    const filterValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                    setData({ ...data, phoneNumber: filterValue })
                                }}
                                isInvalid={isSubmitted && (!data.phoneNumber || !phone_pattern.test(data.phoneNumber))}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!data.phoneNumber ? "phonenumber is required" : !phone_pattern.test(data.phoneNumber) ? "phonenumber is invaild" : ""}</Form.Control.Feedback>
                        </Form.Group>
                        <h4 className="step-small-title mb-0 mt-3">References :</h4>
                        <p clas>{"("}provide at least 2 references{")"}</p>
                        {
                            data.reference?.map((item, index) =>
                                <div key={index} className='applying-item'>
                                    <div>
                                        <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                            <div className='application-name'>{item.name}</div>
                                        </h4>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='mb-1'>
                                                    <span className='box-sub-title'>Reference: </span>{item.fullName}
                                                </p>
                                                <p className='mb-1'>
                                                    <span className='box-sub-title'>Relationship: </span>{item.relationship}
                                                </p>
                                                <p className='mb-1'>
                                                    <span className='box-sub-title'>Phone Number: </span>{item.phoneNumber}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <button onClick={() => handleEdit(item, index)} type='button' className='action-btn'>
                                                <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete(index)} type='button' className='action-btn'>
                                                <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <button type='button' className='action-btn mt-3' onClick={() => { setReferenceModal(true) }}>
                            <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                            Add Additional Reference
                        </button>
                        {(data.reference.length < 2 && isSubmitted) ? <div className="text-danger error-msg">Add minimum 2 references required</div> : ''}
                        <Form.Group as={Col} md="12" className='mb-3' controlId="additionalComments">
                            <h4 className="step-small-title mb-2 mt-3">Additional Comments <span style={{ opacity: '0.8' }}>(Optional)</span></h4>
                            <p className='mb-1'>Use this field to explain or comment on any aspect of your rental application.</p>
                            <Form.Control
                                as="textarea"
                                name="additionalComments"
                                style={{ height: '160px' }}
                                value={data.additionalComments}
                                onChange={(e) => setData({ ...data, additionalComments: e.target.value })}
                            />
                        </Form.Group>
                    </Row>
                    <Button type="submit" className='text-uppercase step-button d-block mx-auto mt-3' disabled={isMainLoader}>{isMainLoader ? <Loader /> : 'Next'}</Button>
                    {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section>
        </>
    )
});

export default StepEight