import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

function LoginWithFacebook({ text }) {

    return (
        <button className='fb-btn' onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}>
            <svg width="18" height="18" viewBox="0 0 18 18"><path d="M18 9a9 9 0 10-10.406 8.89v-6.288H5.309V9h2.285V7.017c0-2.255 1.343-3.501 3.4-3.501.984 0 2.014.175 2.014.175v2.215h-1.135c-1.118 0-1.467.694-1.467 1.406V9h2.496l-.399 2.602h-2.097v6.289C14.71 17.215 18 13.492 18 9" fill="#FFF"></path></svg>
            <span className='d-block'>{text}</span>
        </button>
    );
}

export default LoginWithFacebook;