import Amplify from "@aws-amplify/core"

import { AMPLIFY_AUTH, REACT_APP_REGION, REACT_APP_API_ENDPOINT, REACT_APP_API_NAME } from './config/aws-config.js';

export function configureAmplify() {
  Amplify.configure({
    Auth: AMPLIFY_AUTH,
    API: {
      endpoints: [
        {
          name: REACT_APP_API_NAME,
          endpoint: REACT_APP_API_ENDPOINT,
          region: REACT_APP_REGION,
          paths: ['/']
        }
      ]
    },
    "oauth": {
      "domain": "denaliadmin64f20a11-64f20a11-dev.auth.us-east-1.amazoncognito.com",
      "scope": [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": process.env.REACT_APP_REDIRECT_URL,
      "responseType": "code"
    },
  })
}