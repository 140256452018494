import React from 'react'
import { Button } from 'react-bootstrap'

const StepTen = ({setActiveStep, setIsMainApp, setApplicationData, setIsAppListOpen }) => {
    
    const handleClick = () => {
        setActiveStep(0)
        setIsMainApp(true)
        setApplicationData([])
        setIsAppListOpen(false)
    }

    return (
        <>
            <section className='step-section step-2'>
                <h3 className='step-title mt-3 mb-3 text-center'>Successfully Submitted Form</h3>
                <Button type="submit" onClick={handleClick} className='text-uppercase step-button d-block mx-auto mt-3'>Back to home</Button>
            </section>
        </>
    )
}

export default StepTen