import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Loader from './Loader'

const StepSeven = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props

    const [data, setData] = useState({
        isOwedMoney: "No",
        owedMoney: "",
        isCriminalOffense: "No",
        criminalOffense: "",
        isBankruptcy: "No",
        bankruptcy: "",
        isBreakRentalAgreement: "No",
        breakRentalAgreement: "",
        isPlacedWithCC: "No",
        placedWithCC: "",
        isDueToLandLord: "No",
        dueToLandLord: "",
        isLatePayment: "No",
        latePayment: "",
        isfiledBankruptcyPetition: "No",
        filedBankruptcyPetition: "",
        isRestrainingOrder: "No",
        restrainingOrder: "",
        isUseIllegalDrugs: "No",
        useIllegalDrugs: "",
        isSaleIllegalDrugs: "No",
        saleIllegalDrugs: "",
        isOutstandingWarrants: "No",
        outstandingWarrants: "",
        isMemberOfGang: "No",
        memberOfGang: "",
        isUseTobaccoProducts: "No",
        useTobaccoProducts: "",
        isChargedForCrimes: "No",
        chargedForCrimes: "",
    })

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAnyError, setIsAnyError] = useState({value: false, isCheck: false})


    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
    }, [])

    useEffect(() => {
        if(isAnyError.isCheck) {
            if(
                (data.isOwedMoney === "Yes" && !data.owedMoney)
                || (data.isCriminalOffense === "Yes" && !data.criminalOffense)
                || (data.isBankruptcy === "Yes" && !data.bankruptcy)
                || (data.isBreakRentalAgreement === "Yes" && !data.breakRentalAgreement)
                || (data.isPlacedWithCC === "Yes" && !data.placedWithCC)
                || (data.isDueToLandLord === "Yes" && !data.dueToLandLord)
                || (data.isLatePayment === "Yes" && !data.latePayment)
                || (data.isfiledBankruptcyPetition === "Yes" && !data.filedBankruptcyPetition)
                || (data.isRestrainingOrder === "Yes" && !data.restrainingOrder)
                || (data.isUseIllegalDrugs === "Yes" && !data.useIllegalDrugs)
                || (data.isSaleIllegalDrugs === "Yes" && !data.saleIllegalDrugs)
                || (data.isOutstandingWarrants === "Yes" && !data.outstandingWarrants)
                || (data.isMemberOfGang === "Yes" && !data.memberOfGang)
                || (data.isUseTobaccoProducts === "Yes" && !data.useTobaccoProducts)
                || (data.isChargedForCrimes === "Yes" && !data.chargedForCrimes)
            ) { setIsAnyError({value: true, isCheck: true}) }
            else { setIsAnyError({value: false, isCheck: true})}
        }
    }, [data]);

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 7,
            stepData: data,
        }
        return dataObj;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)
        if(
            (data.isOwedMoney === "Yes" && !data.owedMoney)
            || (data.isCriminalOffense === "Yes" && !data.criminalOffense)
            || (data.isBankruptcy === "Yes" && !data.bankruptcy)
            || (data.isBreakRentalAgreement === "Yes" && !data.breakRentalAgreement)
            || (data.isPlacedWithCC === "Yes" && !data.placedWithCC)
            || (data.isDueToLandLord === "Yes" && !data.dueToLandLord)
            || (data.isLatePayment === "Yes" && !data.latePayment)
            || (data.isfiledBankruptcyPetition === "Yes" && !data.filedBankruptcyPetition)
            || (data.isRestrainingOrder === "Yes" && !data.restrainingOrder)
            || (data.isUseIllegalDrugs === "Yes" && !data.useIllegalDrugs)
            || (data.isSaleIllegalDrugs === "Yes" && !data.saleIllegalDrugs)
            || (data.isOutstandingWarrants === "Yes" && !data.outstandingWarrants)
            || (data.isMemberOfGang === "Yes" && !data.memberOfGang)
            || (data.isUseTobaccoProducts === "Yes" && !data.useTobaccoProducts)
            || (data.isChargedForCrimes === "Yes" && !data.chargedForCrimes)
        ) {
            setIsAnyError({value: true, isCheck: true})
            return
        }

        await setMainData(7, data)
        setActiveStep(8)
    }

    return (
        <>
            <section className='step-section step-7'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-3'>Background</h3>
                <Form onSubmit={handleNext}>
                    <h6 className="mt-4 mb-2 question">Have you (or any person you have named on this application) ever been evicted from a tenancy or left owing money?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="owedMoney"
                        type='radio'
                        id="background1Yes"
                        checked={data.isOwedMoney === 'Yes'}
                        onChange={() => setData({ ...data, isOwedMoney: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="owedMoney"
                        type="radio"
                        id="background1No"
                        checked={data.isOwedMoney === 'No'}
                        onChange={() => setData({ ...data, isOwedMoney: 'No', owedMoney: "" })}
                    />
                    {data.isOwedMoney === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="owedMoney"
                                style={{ height: '150px' }}
                                value={data.owedMoney}
                                onChange={(e) => setData({ ...data, owedMoney: e.target.value })}
                                isInvalid={isSubmitted && data.isOwedMoney && !data.owedMoney}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you or any member of your household ever been convicted of (or pled guilty or no contest to) any criminal offense(s) other than a minor infraction(s) that were disposed of by means other than acquittal or a finding of "not guilty"?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="criminalOffense"
                        type='radio'
                        id="background2Yes"
                        checked={data.isCriminalOffense === 'Yes'}
                        onChange={() => setData({ ...data, isCriminalOffense: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="criminalOffense"
                        type="radio"
                        id="background2No"
                        checked={data.isCriminalOffense === 'No'}
                        onChange={() => setData({ ...data, isCriminalOffense: 'No', criminalOffense: "" })}
                    />
                    {data.isCriminalOffense === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="criminalOffense"
                                style={{ height: '150px' }}
                                value={data.criminalOffense}
                                onChange={(e) => setData({ ...data, criminalOffense: e.target.value })}
                                isInvalid={isSubmitted && data.isCriminalOffense && !data.criminalOffense}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you (or any person you have named on this application) ever filed for or been involved in a bankruptcy, been foreclosed on, or been a defendant in a civil suit?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="bankruptcy"
                        type='radio'
                        id="background4Yes"
                        checked={data.isBankruptcy === 'Yes'}
                        onChange={() => setData({ ...data, isBankruptcy: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="bankruptcy"
                        type="radio"
                        id="background4No"
                        checked={data.isBankruptcy === 'No'}
                        onChange={() => setData({ ...data, isBankruptcy: 'No', bankruptcy: "" })}
                    />
                    {data.isBankruptcy === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="bankruptcy"
                                style={{ height: '150px' }}
                                value={data.bankruptcy}
                                onChange={(e) => setData({ ...data, bankruptcy: e.target.value })}
                                isInvalid={isSubmitted && data.isBankruptcy && !data.bankruptcy}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you or any household member ever broken a rental agreement?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="breakAgreement"
                        type='radio'
                        id="background3Yes"
                        checked={data.isBreakRentalAgreement === 'Yes'}
                        onChange={() => setData({ ...data, isBreakRentalAgreement: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="breakAgreement"
                        type="radio"
                        id="background3No"
                        checked={data.isBreakRentalAgreement === 'No'}
                        onChange={() => setData({ ...data, isBreakRentalAgreement: 'No', breakRentalAgreement: "" })}
                    />
                    {data.isBreakRentalAgreement === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="breakRentalAgreement"
                                style={{ height: '150px' }}
                                value={data.breakRentalAgreement}
                                onChange={(e) => setData({ ...data, breakRentalAgreement: e.target.value })}
                                isInvalid={isSubmitted && data.isBreakRentalAgreement && !data.breakRentalAgreement}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you ever been placed with a collection company for a landlord or utility account balance?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="placedWithCC"
                        type='radio'
                        id="background5Yes"
                        checked={data.isPlacedWithCC === 'Yes'}
                        onChange={() => setData({ ...data, isPlacedWithCC: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="placedWithCC"
                        type="radio"
                        id="background5No"
                        checked={data.isPlacedWithCC === 'No'}
                        onChange={() => setData({ ...data, isPlacedWithCC: 'No', placedWithCC: "" })}
                    />
                    {data.isPlacedWithCC === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="placedWithCC"
                                style={{ height: '150px' }}
                                value={data.placedWithCC}
                                onChange={(e) => setData({ ...data, placedWithCC: e.target.value })}
                                isInvalid={isSubmitted && data.isPlacedWithCC && !data.placedWithCC}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you have any past due / unpaid balances to a landlord, credit card, utility or other loan?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="dueToLandlord"
                        type='radio'
                        id="background6Yes"
                        checked={data.isDueToLandLord === 'Yes'}
                        onChange={() => setData({ ...data, isDueToLandLord: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="dueToLandlord"
                        type="radio"
                        id="background6No"
                        checked={data.isDueToLandLord === 'No'}
                        onChange={() => setData({ ...data, isDueToLandLord: 'No', dueToLandLord: "" })}
                    />
                    {data.isDueToLandLord === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="dueToLandLord"
                                style={{ height: '150px' }}
                                value={data.dueToLandLord}
                                onChange={(e) => setData({ ...data, dueToLandLord: e.target.value })}
                                isInvalid={isSubmitted && data.isDueToLandLord && !data.dueToLandLord}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you ever been more than 10 days late on your rent or mortgage payment?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="latePayment"
                        type='radio'
                        id="background7Yes"
                        checked={data.isLatePayment === 'Yes'}
                        onChange={() => setData({ ...data, isLatePayment: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="latePayment"
                        type="radio"
                        id="background7No"
                        checked={data.isLatePayment === 'No'}
                        onChange={() => setData({ ...data, isLatePayment: 'No', latePayment: "" })}
                    />
                    {data.isLatePayment === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="latePayments"
                                style={{ height: '150px' }}
                                value={data.latePayment}
                                onChange={(e) => setData({ ...data, latePayment: e.target.value })}
                                isInvalid={isSubmitted && data.isLatePayment && !data.latePayment}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you or any household member ever filed a petition for bankruptcy?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="bankruptcyPetition"
                        type='radio'
                        id="background8Yes"
                        checked={data.isfiledBankruptcyPetition === 'Yes'}
                        onChange={() => setData({ ...data, isfiledBankruptcyPetition: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="bankruptcyPetition"
                        type="radio"
                        id="background8No"
                        checked={data.isfiledBankruptcyPetition === 'No'}
                        onChange={() => setData({ ...data, isfiledBankruptcyPetition: 'No', filedBankruptcyPetition: "" })}
                    />
                    {data.isfiledBankruptcyPetition === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="filedBankruptcyPetition"
                                style={{ height: '150px' }}
                                value={data.filedBankruptcyPetition}
                                onChange={(e) => setData({ ...data, filedBankruptcyPetition: e.target.value })}
                                isInvalid={isSubmitted && data.isfiledBankruptcyPetition && !data.filedBankruptcyPetition}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you or any household member ever had a restraining order against you?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="restrainingOrder"
                        type='radio'
                        id="background10Yes"
                        checked={data.isRestrainingOrder === 'Yes'}
                        onChange={() => setData({ ...data, isRestrainingOrder: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="restrainingOrder"
                        type="radio"
                        id="background10No"
                        checked={data.isRestrainingOrder === 'No'}
                        onChange={() => setData({ ...data, isRestrainingOrder: 'No', restrainingOrder: "" })}
                    />
                    {data.isRestrainingOrder === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="restrainingOrder"
                                style={{ height: '150px' }}
                                value={data.restrainingOrder}
                                onChange={(e) => setData({ ...data, restrainingOrder: e.target.value })}
                                isInvalid={isSubmitted && data.isRestrainingOrder && !data.restrainingOrder}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you or any household member use or manufacture illegal drugs?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="useIllegalDrugs"
                        type='radio'
                        id="background11Yes"
                        checked={data.isUseIllegalDrugs === 'Yes'}
                        onChange={() => setData({ ...data, isUseIllegalDrugs: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="useIllegalDrugs"
                        type="radio"
                        id="background11No"
                        checked={data.isUseIllegalDrugs === 'No'}
                        onChange={() => setData({ ...data, isUseIllegalDrugs: 'No', useIllegalDrugs: "" })}
                    />
                    {data.isUseIllegalDrugs === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="useIllegalDrugs"
                                style={{ height: '150px' }}
                                value={data.useIllegalDrugs}
                                onChange={(e) => setData({ ...data, useIllegalDrugs: e.target.value })}
                                isInvalid={isSubmitted && data.isUseIllegalDrugs && !data.useIllegalDrugs}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you or any household member engage in the distribution or sale of illegal drugs?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="saleIllegalDrugs"
                        type='radio'
                        id="background12Yes"
                        checked={data.isSaleIllegalDrugs === 'Yes'}
                        onChange={() => setData({ ...data, isSaleIllegalDrugs: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="saleIllegalDrugs"
                        type="radio"
                        id="background12No"
                        checked={data.isSaleIllegalDrugs === 'No'}
                        onChange={() => setData({ ...data, isSaleIllegalDrugs: 'No', saleIllegalDrugs: "" })}
                    />
                    {data.isSaleIllegalDrugs === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="saleIllegalDrugs"
                                style={{ height: '150px' }}
                                value={data.saleIllegalDrugs}
                                onChange={(e) => setData({ ...data, saleIllegalDrugs: e.target.value })}
                                isInvalid={isSubmitted && data.isSaleIllegalDrugs && !data.saleIllegalDrugs}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you or any household member have any outstanding warrants for your arrest?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="outstandingWarrants"
                        type='radio'
                        id="background13Yes"
                        checked={data.isOutstandingWarrants === 'Yes'}
                        onChange={() => setData({ ...data, isOutstandingWarrants: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="outstandingWarrants"
                        type="radio"
                        id="background13No"
                        checked={data.isOutstandingWarrants === 'No'}
                        onChange={() => setData({ ...data, isOutstandingWarrants: 'No', outstandingWarrants: "" })}
                    />
                    {data.isOutstandingWarrants === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="outstandingWarrants"
                                style={{ height: '150px' }}
                                value={data.outstandingWarrants}
                                onChange={(e) => setData({ ...data, outstandingWarrants: e.target.value })}
                                isInvalid={isSubmitted && data.isOutstandingWarrants && !data.outstandingWarrants}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you or any household member ever been or currently are member of a gang?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="memberOfGang"
                        type='radio'
                        id="background14Yes"
                        checked={data.isMemberOfGang === 'Yes'}
                        onChange={() => setData({ ...data, isMemberOfGang: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="memberOfGang"
                        type="radio"
                        id="background14No"
                        checked={data.isMemberOfGang === 'No'}
                        onChange={() => setData({ ...data, isMemberOfGang: 'No', memberOfGang: "" })}
                    />
                    {data.isMemberOfGang === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="memberOfGang"
                                style={{ height: '150px' }}
                                value={data.memberOfGang}
                                onChange={(e) => setData({ ...data, memberOfGang: e.target.value })}
                                isInvalid={isSubmitted && data.isMemberOfGang && !data.memberOfGang}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Do you or any household member use any tobacco products?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="useTobaccoProducts"
                        type='radio'
                        id="background15Yes"
                        checked={data.isUseTobaccoProducts === 'Yes'}
                        onChange={() => setData({ ...data, isUseTobaccoProducts: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="useTobaccoProducts"
                        type="radio"
                        id="background15No"
                        checked={data.isUseTobaccoProducts === 'No'}
                        onChange={() => setData({ ...data, isUseTobaccoProducts: 'No', useTobaccoProducts: "" })}
                    />
                    {data.isUseTobaccoProducts === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="useTobaccoProducts"
                                style={{ height: '150px' }}
                                value={data.useTobaccoProducts}
                                onChange={(e) => setData({ ...data, useTobaccoProducts: e.target.value })}
                                isInvalid={isSubmitted && data.isUseTobaccoProducts && !data.useTobaccoProducts}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }
                    <h6 className="mt-4 mb-2 question">Have you or any household member ever been charged of any felony or misdemeanor relating to arson, assault, intimidation, sex crimes, drug-related offenses, theft, dishonesty, prostitution, or related violations or any crime related to harm caused to a person or property?</h6>
                    <Form.Check
                        inline
                        label="Yes"
                        name="chargedForCrimes"
                        type='radio'
                        id="background16Yes"
                        checked={data.isChargedForCrimes === 'Yes'}
                        onChange={() => setData({ ...data, isChargedForCrimes: 'Yes' })}
                    />
                    <Form.Check
                        inline
                        label="No"
                        name="chargedForCrimes"
                        type="radio"
                        id="background16No"
                        checked={data.isChargedForCrimes === 'No'}
                        onChange={() => setData({ ...data, isChargedForCrimes: 'No', chargedForCrimes: "" })}
                    />
                    {data.isChargedForCrimes === "Yes" &&
                        <Form.Group as={Col} md="12" className='mb-3 mt-2'>
                            <Form.Label>Please explain (required):</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="chargedForCrimes"
                                style={{ height: '150px' }}
                                value={data.chargedForCrimes}
                                onChange={(e) => setData({ ...data, chargedForCrimes: e.target.value })}
                                isInvalid={isSubmitted && data.isChargedForCrimes && !data.chargedForCrimes}
                            />
                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">explaination is required</Form.Control.Feedback>
                        </Form.Group>
                    }

                    <Button type="submit" className='text-uppercase step-button d-block mx-auto mt-4' disabled={isMainLoader}>{isMainLoader ? <Loader /> : 'Next'}</Button>
                    {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section>
        </>
    )
});

export default StepSeven