import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Loader from './Loader';

const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]
const StepFour = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props

    let phone_pattern = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;

    const [employmentModal, setEmploymentModal] = useState(false)
    const [data, setData] = useState({
        currentEmployers: [],
        pastEmployers: [],
        currentlyEmployed: true,
        isPastEmployersApplicable: true,
        currentEmployerCircumstance: '',
        reasonNotCurrentlyEmployed: ''
    })
    const [currentModal, setCurrentModal] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEmployerSubmitted, setIsEmployerSubmitted] = useState(false);
    const [editEmployer, setEditEmployer] = useState(null);
    const [yearCount, setYearCount] = useState()
    const [yearCountError, setYearCountError] = useState(false)
    const [isAnyError, setIsAnyError] = useState({ value: false, isCheck: false })
    const [yearStartEndError, setYearStartEndError] = useState(false)
    const [employerData, setEmployerData] = useState({
        name: "",
        title: "",
        monthStarted: "January",
        yearStarted: new Date().getFullYear(),
        monthEnded: "January",
        yearEnded: new Date().getFullYear(),
        monthlyIncome: "",
        reference: {
            fullName: "",
            phonenumber: ""
        }
    })

    const [errors, setErrors] = useState({
        currentEmployers: "",
        pastEmployers: ""
    });

    useEffect(() => {
        if(activeData) {
            setData({
                ...activeData,
                currentEmployerCircumstance: activeData.currentEmployerCircumstance || ''
            })
        }
    }, [])

    useEffect(() => {
        handleYearCount(data)
        if(isAnyError.isCheck) {
            if(
                (data.currentlyEmployed && !data.currentEmployers.length) ||
                (data.isPastEmployersApplicable && !data.pastEmployers.length) ||
                (yearCountError && !data.currentEmployerCircumstance) ||
                (!data.currentlyEmployed && !data.reasonNotCurrentlyEmployed)
            ) setIsAnyError({ value: true, isCheck: true })
            else setIsAnyError({ value: false, isCheck: true })
        }
    }, [data])

    useEffect(() => {
        if(currentModal === "Past") {
            validateDates()
        }
    }, [employerData])


    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 4,
            stepData: data,
        }
        return dataObj;
    };

    const handleYearCount = (data, update) => {
        let yearList = [];
        let yearListPast = [];
        let mainYearList = [];
        let countYear = 0;
        for(let i = 0; i < data.currentEmployers.length; i++) {
            let obj = { month: data.currentEmployers[i].monthStarted, year: data.currentEmployers[i].yearStarted }
            yearList = [...yearList, obj]
        }
        for(let i = 0; i < data.pastEmployers.length; i++) {
            let obj = {
                monthStart: data.pastEmployers[i].monthStarted,
                yearStart: data.pastEmployers[i].yearStarted,
                monthEnd: data.pastEmployers[i].monthEnded,
                yearEnd: data.pastEmployers[i].yearEnded,
            }
            yearListPast = [...yearListPast, obj]
        }
        const yearDifferences = calculateYearDifference(yearList);
        const yearDifferencesPast = yearListPast.map(calculateYearMonthDifference);

        mainYearList = [...yearDifferences, ...yearDifferencesPast]

        for(let i = 0; i < mainYearList.length; i++) {
            let mainYearLists = mainYearList[i] ? mainYearList[i] : 0
            countYear = countYear + mainYearLists;
        }
        setYearCount(countYear)
        if((data.currentEmployers.length || data.pastEmployers.length) || (!data.currentlyEmployed || !data.isPastEmployersApplicable)) {
            if(countYear >= 5) {
                if(data.currentEmployers.length && update) {
                    setData((prev) => ({ ...prev, isPastEmployersApplicable: false }))
                }
                setYearCountError(false)
            } else {
                setYearCountError(true)
            }
        }
        // else {
        //     setYearCountError(false)
        // }
    }

    function calculateYearDifference(yearlist) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const differences = yearlist.map(item => {
            const itemYear = parseInt(item.year);
            const itemMonth = new Date(`${item.month} 1, ${itemYear}`);
            const yearDifference = currentYear - itemYear;
            const monthDifference = currentMonth - (itemMonth.getMonth() + 1);
            const mainYear = yearDifference + (monthDifference / 12)
            return mainYear;
        });
        return differences;
    }

    function calculateYearMonthDifference(range) {
        const startMonth = new Date(`${range.monthStart} 1, ${range.yearStart}`);
        const endMonth = new Date(`${range.monthEnd} 1, ${range.yearEnd}`);
        const diffMonths = (endMonth - startMonth) / (1000 * 60 * 60 * 24 * 30);
        let mainYear = (Math.round(diffMonths))
        return (mainYear / 12);
    }

    const handleEmployerSave = (e) => {
        e.preventDefault()
        setIsEmployerSubmitted(true)
        if(((currentModal === "Past") && yearStartEndError) || !employerData.name || !employerData.title || !employerData.monthlyIncome || !employerData.reference.fullName || (!employerData.reference.phonenumber || !phone_pattern.test(employerData.reference.phonenumber))) {
            return
        } else {
            if(currentModal === "Current") {
                setErrors({ ...errors, currentEmployers: "" })
            } else if(currentModal === "Past") {
                setErrors({ ...errors, pastEmployers: "" })
            }
        }
        if(editEmployer !== null) {
            if(currentModal === "Current") {
                let updatedData = [...data.currentEmployers]
                updatedData = updatedData.map((item, index) => {
                    if(index === editEmployer) {
                        item = employerData
                    }
                    return item
                })
                setData({ ...data, currentEmployers: updatedData })
            } else if(currentModal === "Past") {
                let updatedData = [...data.pastEmployers]
                updatedData = updatedData.map((item, index) => {
                    if(index === editEmployer) {
                        item = employerData
                    }
                    return item
                })
                setData({ ...data, pastEmployers: updatedData })
            }
        } else {
            if(currentModal === "Current") {
                setData({ ...data, currentEmployers: [...data.currentEmployers, employerData] })
            } else if(currentModal === "Past") {
                setData({ ...data, pastEmployers: [...data.pastEmployers, employerData] })
            }
        }

        // currentEmployers: [],
        // pastEmployers: [],
        // currentlyEmployed: true,
        // isPastEmployersApplicable: true,
        // currentEmployerCircumstance: '',
        // reasonNotCurrentlyEmployed: ''

        handleYearCount({
            currentEmployers: [...data.currentEmployers, currentModal === "Current" && employerData],
            pastEmployers: [...data.pastEmployers, currentModal === "Past" && employerData],
            currentlyEmployed: data.currentlyEmployed,
            isPastEmployersApplicable: data.isPastEmployersApplicable,
            currentEmployerCircumstance: data.currentEmployerCircumstance,
            reasonNotCurrentlyEmployed: data.reasonNotCurrentlyEmployed
        }, true)

        // let yearList = [];
        // let yearListPast = [];
        // let mainYearList = [];
        // let countYear = 0;
        // for(let i = 0; i < [...data.currentEmployers, currentModal === "Current" && employerData].length; i++) {
        //     let obj = { month: [...data.currentEmployers, currentModal === "Current" && employerData ][i].monthStarted, year: [...data.currentEmployers, currentModal === "Current" && employerData][i].yearStarted }
        //     yearList = [...yearList, obj]
        // }
        // for(let i = 0; i < [...data.pastEmployers, currentModal === "Past" && employerData].length; i++) {
        //     let obj = {
        //         monthStart: [...data.pastEmployers, currentModal === "Past" && employerData][i].monthStarted,
        //         yearStart: [...data.pastEmployers, currentModal === "Past" && employerData][i].yearStarted,
        //         monthEnd: [...data.pastEmployers, currentModal === "Past" && employerData][i].monthEnded,
        //         yearEnd: [...data.pastEmployers, currentModal === "Past" && employerData][i].yearEnded,
        //     }
        //     yearListPast = [...yearListPast, obj]
        // }
        // const yearDifferences = calculateYearDifference(yearList);
        // const yearDifferencesPast = yearListPast.map(calculateYearMonthDifference);

        // console.log('::::::::::::',[yearDifferences[yearDifferences.length - 1]], [yearDifferencesPast[yearDifferencesPast.length - 1]]);
        // mainYearList = [yearDifferences[yearDifferences.length - 1], yearDifferencesPast[yearDifferencesPast.length - 1]]

        // for(let i = 0; i < mainYearList.length; i++) {
        //     let mainYearLists = mainYearList[i] ? mainYearList[i] : 0
        //     countYear = countYear + mainYearLists;
        // }
        // setYearCount(countYear)
        // if(([...data.currentEmployers, currentModal === "Current" && employerData].length || [...data.pastEmployers, currentModal === "Past" && employerData].length) || (!data.currentlyEmployed || !data.isPastEmployersApplicable)) {
        //     if(countYear >= 5) {
        //         console.log('>>>>>> ifff',countYear);

        //         setYearCountError(false)
        //         if([...data.currentEmployers, currentModal === "Current" && employerData].length) {
        //             setData((prev) => ({ ...prev, isPastEmployersApplicable: false }))
        //         }
        //     } else {
        //         console.log('>>>>>> ifff elseeee',countYear);
        //         setYearCountError(true)
        //     }
        // } else {
        //     console.log('>>>>> elseee',countYear);

        //     // setYearCountError(false)
        //     if([...data.currentEmployers, employerData].length) {
        //         setData((prev) => ({ ...prev, isPastEmployersApplicable: false }))
        //     }
        // }

        setEmploymentModal(false)
        setEmployerData({
            name: "",
            title: "",
            monthStarted: "January",
            yearStarted: new Date().getFullYear(),
            monthEnded: "January",
            yearEnded: new Date().getFullYear(),
            monthlyIncome: "",
            reference: {
                fullName: "",
                phonenumber: ""
            }
        })
        setEditEmployer(null)
        setIsEmployerSubmitted(false)
    }

    const handleNext = async () => {
        setIsSubmitted(true)
        let error = {};
        let isError = false;
        if(data.currentlyEmployed && !data.currentEmployers.length) {
            error = { ...error, currentEmployers: "minimum 1 current employer is required" }
            isError = true
        }
        if(data.isPastEmployersApplicable && !data.pastEmployers.length) {
            error = { ...error, pastEmployers: "minimum 1 past employer is required" }
            isError = true
        }
        if(isError || (yearCountError && !data.currentEmployerCircumstance) || (!data.currentlyEmployed && !data.reasonNotCurrentlyEmployed)) {
            setErrors(error)
            setIsAnyError({ value: true, isCheck: true })
            return
        }

        let mainFormData = data;
        if(!yearCountError) {
            mainFormData = { ...mainFormData, currentEmployerCircumstance: "" }
        }
        if(data.currentlyEmployed || data.isPastEmployersApplicable) {
            setYearCountError(false)
            await setMainData(4, mainFormData)
            setActiveStep(5)
        } else {
            setYearCountError(false)
            await setMainData(4, mainFormData)
            setActiveStep(5)
        }
    }

    const handleChange = (key, value) => {
        if(key === "Current") {
            if(value && isSubmitted) {
                setErrors({ ...errors, currentEmployers: "" })
            }
            setData({ ...data, currentlyEmployed: !value })
        } else if(key === "Past") {
            if(value && isSubmitted) {
                setErrors({ ...errors, pastEmployers: "" })
            }
            setData({ ...data, isPastEmployersApplicable: !value })
        }
    }

    const handleEdit = (modal, item, i) => {
        setEmployerData(item)
        setCurrentModal(modal)
        setEmploymentModal(true)
        setEditEmployer(i)
    }

    const handleDelete = (key, index) => {
        setData({ ...data, [key]: data[key].filter((item, i) => index !== i) })
    }

    const validateDates = () => {
        const startDate = new Date(employerData.yearStarted, monthList.indexOf(employerData.monthStarted));
        const endDate = new Date(employerData.yearEnded, monthList.indexOf(employerData.monthEnded));
        if(startDate > endDate) {
            setYearStartEndError(true)
        } else {
            setYearStartEndError(false)
        }
    };

    return (
        <>
            {/* Employment Modal Start */}
            <Modal
                show={employmentModal}
                onHide={() => setEmploymentModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='border-0'></Modal.Header>
                <Modal.Body>
                    <h3 className='step-title mb-0'>{editEmployer !== null ? "Edit" : "Add"} a {currentModal} Employer</h3>
                    <Form className='mt-4' onSubmit={handleEmployerSave}>
                        <Row>
                            <Form.Group as={Col} className='mb-3' md="12" controlId="name">
                                <Form.Label>Employer</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={employerData.name}
                                    onChange={(e) => setEmployerData({ ...employerData, name: e.target.value })}
                                    isInvalid={isEmployerSubmitted && !employerData.name}
                                />
                                <Form.Control.Feedback type="invalid">employer name is required</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='mb-3' md="12" controlId="title">
                                <Form.Label>Position / Title / Occupation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={employerData.title}
                                    onChange={(e) => setEmployerData({ ...employerData, title: e.target.value })}
                                    isInvalid={isEmployerSubmitted && !employerData.title}
                                />
                                <Form.Control.Feedback type="invalid">position is required</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="5" className='mb-3 col-6' controlId='monthStarted'>
                                <Form.Label>Month Started</Form.Label>
                                <Form.Select
                                    name="monthStarted"
                                    value={employerData.monthStarted}
                                    onChange={(e) => {
                                        setEmployerData({ ...employerData, monthStarted: e.target.value })
                                        // validateDates()
                                    }}
                                >
                                    {
                                        monthList.map((item, i) => {
                                            return (
                                                <option key={i} value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className='mb-3 col-6' controlId='yearStarted'>
                                <Form.Label>Year Started</Form.Label>
                                <Form.Select
                                    name="yearStarted"
                                    value={employerData.yearStarted}
                                    onChange={(e) => {
                                        setEmployerData({ ...employerData, yearStarted: e.target.value })
                                        // validateDates()
                                    }}
                                >
                                    {Array.from(Array(50)).map((item, index) =>
                                        <option value={new Date().getFullYear() - index} key={index}>{new Date().getFullYear() - index}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                            {currentModal === "Past" &&
                                <>
                                    <Form.Group as={Col} md="5" className='mb-1 col-6' controlId='monthEnded'>
                                        <Form.Label>Month Ended</Form.Label>
                                        <Form.Select
                                            name="monthEnded"
                                            value={employerData.monthEnded}
                                            onChange={(e) => {
                                                setEmployerData({ ...employerData, monthEnded: e.target.value })
                                                // validateDates()
                                            }}
                                        >
                                            {
                                                monthList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" className='mb-1 col-6' controlId='yearEnded'>
                                        <Form.Label>Year Ended</Form.Label>
                                        <Form.Select
                                            name="yearEnded"
                                            value={employerData.yearEnded}
                                            onChange={(e) => {
                                                setEmployerData({ ...employerData, yearEnded: e.target.value })
                                                // validateDates()
                                            }}
                                        >
                                            {Array.from(Array(50)).map((item, index) =>
                                                <option value={new Date().getFullYear() - index} key={index}>{new Date().getFullYear() - index}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                    {yearStartEndError && <Form.Control.Feedback type="invalid" className="text-danger d-block mb-1 only-error-msg">Start month and year is greater than end month and year </Form.Control.Feedback>}
                                </>

                            }
                            <Form.Group as={Col} className='mb-3 mt-3 col-6 price-input' md="6" controlId="monthlyIncome">
                                <Form.Label>Monthly Income</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="monthlyIncome"
                                    value={employerData.monthlyIncome}
                                    onChange={(e) => setEmployerData({ ...employerData, monthlyIncome: e.target.value })}
                                    isInvalid={isEmployerSubmitted && !employerData.monthlyIncome}
                                />
                                <Form.Control.Feedback type="invalid">monthly income is required</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <h4 className='step-small-title mb-3 mt-2'>Employment Reference</h4>
                        <Row>
                            <Form.Group as={Col} className='mb-3' md="12" controlId="fullName">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullName"
                                    value={employerData.reference.fullName}
                                    onChange={(e) => setEmployerData({ ...employerData, reference: { ...employerData.reference, fullName: e.target.value } })}
                                    isInvalid={isEmployerSubmitted && !employerData.reference.fullName}
                                />
                                <Form.Control.Feedback type="invalid">fullname is required</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='mb-4' md="6" controlId="phoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="(---) --- - ----"
                                    value={employerData.reference.phonenumber}
                                    onChange={(e) => {
                                        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                        const filterValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                        setEmployerData({ ...employerData, reference: { ...employerData.reference, phonenumber: filterValue } })
                                    }}
                                    isInvalid={isEmployerSubmitted && (!employerData.reference.phonenumber || !phone_pattern.test(employerData.reference.phonenumber))}
                                />
                                <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!employerData.reference.phonenumber ? "phonenumber is required" : !phone_pattern.test(employerData.reference.phonenumber) ? "phonenumber is invaild" : ""}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit" className='text-uppercase step-button d-block mx-auto mb-2'>{editEmployer !== null ? "Edit" : "Add"} {currentModal} Employer</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Employment Modal End */}

            <section className='step-section step-4'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-1'>Employment</h3>
                <p>Show employment history of 5 years.</p>
                <h4 className='step-small-title mb-2 mt-4'>Current Employer</h4>
                {
                    !data.currentEmployers.length &&
                    <Form.Check
                        type='checkbox'
                        id="not-currently-employed"
                        label="I'm not currently employed"
                        checked={!data.currentlyEmployed}
                        onChange={(e) => handleChange("Current", e.target.checked)}
                    />
                }
                {
                    data.currentEmployers.map((item, index) =>
                        <div key={index} className='applying-item'>
                            <div>
                                <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                    <div className='application-name'>{item.name}</div>
                                </h4>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <p className='mb-1'><span className='box-sub-title'>Position: </span>{item.title}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Started: </span>{item.monthStarted} {item.yearStarted}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Monthly Income: </span>${item.monthlyIncome}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='mb-1'>
                                            <span className='box-sub-title'>Employment Reference: </span>
                                            <span className='d-block'>{item.reference.fullName}</span>
                                            <span className='d-block'>{item.reference.phonenumber}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <button onClick={() => handleEdit("Current", item, index)} type='button' className='action-btn'>
                                        <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete("currentEmployers", index)} type='button' className='action-btn'>
                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    data.currentlyEmployed && data.currentEmployers.length === 0 && <button type='button' className='action-btn mt-3' onClick={() => { setEmploymentModal(true); setCurrentModal("Current") }}>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Current Employer
                    </button>
                }
                {!data?.currentlyEmployed && <Form.Group as={Col} md="12" className='my-3' controlId="reasonNotCurrentlyEmployed">
                    <Form.Label>Reason for I am not currently employed</Form.Label>
                    <Form.Control
                        type="text"
                        name="reasonNotCurrentlyEmployed"
                        value={data.reasonNotCurrentlyEmployed}
                        onChange={(e) => setData({ ...data, reasonNotCurrentlyEmployed: e.target.value })}
                        isInvalid={isSubmitted && !data.reasonNotCurrentlyEmployed}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.reasonNotCurrentlyEmployed}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Reason is required</Form.Control.Feedback>
                </Form.Group>}
                {isSubmitted && errors.currentEmployers && <div className="text-danger error-msg">{errors.currentEmployers}</div>}
                <h4 className='step-small-title mb-2 mt-4'>Past Employers</h4>
                <p>We require <b>5 years</b> of employment history on your application, if applicable.</p>
                {
                    !data.pastEmployers.length && <Form.Check
                        type='checkbox'
                        id="not_applicable"
                        label="Check this box if you do not have employment greater than 5 years"
                        checked={!data.isPastEmployersApplicable}
                        onChange={(e) => handleChange("Past", e.target.checked)}
                    />
                }
                {
                    data.pastEmployers.map((item, index) =>
                        <div key={index} className='applying-item'>
                            <div>
                                <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                    <div className='application-name'>{item.name}</div>
                                </h4>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <p className='mb-1'><span className='box-sub-title'>Position: </span>{item.title}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Started: </span>{item.monthStarted} {item.yearStarted}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Ended: </span>{item.monthEnded} {item.yearEnded}</p>
                                        <p className='mb-1'><span className='box-sub-title'>Monthly Income: </span>${item.monthlyIncome}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='mb-1'>
                                            <span className='box-sub-title'>Employment Reference: </span>
                                            <span className='d-block'>{item.reference.fullName}</span>
                                            <span className='d-block'>{item.reference.phonenumber}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <button onClick={() => handleEdit("Past", item, index)} type='button' className='action-btn'>
                                        <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete("pastEmployers", index)} type='button' className='action-btn'>
                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    data.isPastEmployersApplicable && <button type='button' className='action-btn mt-3' onClick={() => { setEmploymentModal(true); setCurrentModal("Past") }}>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Past Employers
                    </button>
                }
                {isSubmitted && errors.pastEmployers && <div className="text-danger error-msg">{errors.pastEmployers}</div>}
                {yearCountError && <Form.Group as={Col} md="12" className='mb-3 mt-3 col-6' controlId='currentEmployerCircumstance'>
                    <Form.Label>Enter reason for not having 5 years of employment history</Form.Label>
                    <Form.Control
                        type="text"
                        name="currentEmployerCircumstance"
                        value={data.currentEmployerCircumstance}
                        onChange={(e) => setData({ ...data, currentEmployerCircumstance: e.target.value })}
                        isInvalid={isSubmitted && !data.currentEmployerCircumstance}
                    />
                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Reason for not having is required</Form.Control.Feedback>
                </Form.Group>}
                {/* {yearCountError && data?.currentEmployerCircumstance === "Other" && <Form.Group as={Col} md="12" className='my-3' controlId="otherCircumstance">
                    <Form.Label>Other Circumstance</Form.Label>
                    <Form.Control
                        type="text"
                        name="otherCircumstance"
                        value={data.otherCircumstance}
                        onChange={(e) => setData({ ...data, otherCircumstance: e.target.value })}
                        isInvalid={isSubmitted && !data.otherCircumstance}
                    />
                    <Form.Control.Feedback type="invalid">{errors.otherCircumstance}</Form.Control.Feedback>
                </Form.Group>} */}
                {yearCountError && (!data?.currentEmployerCircumstance) && <Form.Control.Feedback type="invalid" className="text-danger d-block text-center mt-3 only-error-msg">Need at least 5 years worth of work history or describe the reason for not meeting the requirement.</Form.Control.Feedback>}
                <Button type="button" className='text-uppercase step-button d-block mx-auto mt-4' onClick={handleNext} disabled={isMainLoader}>{isMainLoader ? <Loader /> : 'Next'}</Button>
                {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
            </section>
        </>
    )
});

export default StepFour