import React, { useEffect, useState } from 'react'
import property from '../assets/images/Available-Rentals.png';
import { Col, DropdownButton, Form, ProgressBar, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { REACT_APP_API_NAME } from '../config/aws-config';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import LoaderScreen from './LoaderScreen';
import logo from "../assets/images/DanaliLogo.svg"
import SiteLogo from './SiteLogo';

const propertyList = [
    "10136 E. Southern Avenue, Unit 1077, Mesa, AZ 85209",
    "1029 E. Mariposa Street, Apt 1, Phoenix AZ 85014",
    "10828 N. Biltmore Drive, #238, Phoenix AZ 85029",
    "10920 E. North Lane, Scottsdale, AZ 85259",
    "11101 N. Hydrus Avenue, Oro Valley, AZ 85742",
    "1121 E. Regent Drive, Gilbert, AZ 85298",
    "1125 E. Devonshire Avenue, Unit 10, Phoenix, AZ 85014",
    "1125 E. Devonshire Avenue, Unit 4, Phoenix, AZ 85014",
    "1125 E. Devonshire Avenue, Unit 9, Phoenix, AZ 85014",
    "11500 E Cochise Drive, Unit 1007, Scottsdale, AZ 85259",
    "11500 E Cochise Drive, Unit 2008, Scottsdale, AZ 85259",
    "11786 W. Fordson Drive, Marana AZ 85653 ",
    "11832 E. Clinton Street, Scottsdale, AZ 85259",
    "12 E. Seminole Drive, Phoenix, AZ 85022",
    "1213 E Weatherby Way, Chandler,  AZ 85286",
    "1220 W. Romo Jones Street, Tempe, AZ 85281",
    "1250 S. Rialto, Unit 19, Mesa, AZ 85209",
    "12605 W Columbine Drive, El Mirage, AZ 85335",
    "1294 S. Anvil Place, Chandler, AZ 85286",
    "1336 E. Don Carlos Avenue, Tempe, AZ 85281",
    "1351 N. Pleasant Drive, Unit 2084, Chandler, AZ 85225",
    "13651 N. 33rd Avenue, Phoenix, AZ 85029",
    "13874 N. 91st Drive, Peoria, AZ 85381",
    "14203 N. 101st Street, Scottsdale 85260",
    "14203 N. 19th Avenue, #2023, Phoenix, AZ 85023",
    "14376 W. Voltaire Street, Surprise, AZ 85379",
    "14714 N. 124th Lane El Mirage, AZ 85335",
    "1488 E. Cheyenne Street, Gilbert, AZ 85296",
    "1515 W. Huntington Drive, Tempe, AZ 85282",
    "15550 S. 5th Ave Unit 100, Phoenix, AZ 85045",
    "15620 N. 59th Lane, Glendale, AZ 85306",
    "1602 W Fremont Rd. Phoenix, AZ 85041",
    "16044 E. Sunflower Drive, Unit 2, Fountain Hills AZ 85268 ",
    "1631 E. Flint Street, Chandler, AZ 85225",
    "16415 N. 171st Lane, Surprise, AZ 85388",
    "1646 E. Jarvis Avenue, Mesa, AZ 85204",
    "17143 N. 9th Place, Phoenix, AZ 85022",
    "17413 S. Ridgerunner Drive, Vail, AZ 85641",
    "17442 N. 56th Place, Scottsdale, AZ 85254",
    "17989 W. Encinas Lane, Goodyear, AZ 85338",
    "18019 N. 40th Place, Unit 1, Phoenix, AZ 85032",
    "1810 S. Jesse Place, Chandler, AZ 85286",
    "1955 E. Drake Drive, Tempe, AZ 85283",
    "1965 E. Inverness Circle, Mesa, AZ 85204",
    "2019 E. St. Anne Avenue, Phoenix, AZ 85042",
    "2053 S. Paseo Loma, Mesa, AZ 85202",
    "21046 E. Mayberry Road, Queen Creek, AZ 85142",
    "2138 E. Turney Avenue, Unit A, Phoenix AZ 85016",
    "2138 E. Turney Avenue, Unit D, Phoenix AZ 85016",
    "220 S. Old Litchfield Road Apt 111, Litchfield Park, AZ 85340",
    "2208 W. Lindner Avenue, Unit 45, Mesa, AZ 85202",
    "22410 N Cochise Lane, Sun City West, AZ 85375",
    "22480 E. Via Del Oro, Queen Creek, AZ 85142",
    "2309 S. College Avenue, Unit 1, Tempe AZ 85282",
    "2309 S. College Avenue, Unit 15, Tempe AZ 85282",
    "2309 S. College Avenue, Unit 17, Tempe AZ 85282",
    "2321 W. Tanque Verde Court, Chandler, AZ 85224",
    "2323 E. Harvard Street, Phoenix, AZ 85006",
    "2330 W. Whitton Avenue, Phoenix, AZ 85015",
    "2455 E. Broadway Road Unit 53, Mesa, AZ 85204",
    "2580 E. Megan Street, Gilbert 85295",
    "260 S. Pantano Road, #111, Tucson, AZ 85710",
    "2615 E. Houston Avenue, Apache Junction, AZ 85119",
    "2629 S. Tobin, Mesa, AZ 85209",
    "2660 E. Longhorn Court, Gilbert, AZ 85297",
    "2725 S. Rural Road, Apt 5, Tempe, AZ 85282",
    "2741 E. Bart Street, Gilbert, AZ 85295",
    "2751 E. Tonto Lane, Phoenix, AZ 85050",
    "280 S. Evergreen Road, Unit 1344, Tempe, AZ 85288",
    "2938 N. 61st Place, #257, Scottsdale AZ 85251",
    "29480 N. Gold Lane, San Tan Valley, AZ 85143",
    "3030 N. Hayden Road, #27, Scottsdale AZ 85251 ",
    "3238 N. Jackson Avenue, Tucson, AZ 85719",
    "346 E. Virginia Circle, Phoenix AZ 85004 ",
    "35424 N. 15th Avenue, Phoenix, AZ 85086",
    "36 S. 120th Avenue, Avondale, AZ 85323",
    "3633 N. 3rd Avenue, Unit 2077, Phoenix, AZ 85013",
    "3809 W. Danbury Drive Glendale, AZ 85308",
    "3852 N. Stone Gully, Mesa, AZ 85207",
    "3945 S. Illinois Street, Chandler, AZ 85248",
    "4023 W. Ross Avenue, Glendale, AZ 85308",
    "410 E Linden Street, Unit 1, Tucson AZ 85705 ",
    "4103 E. Moreland Street, Unit 4, Phoenix AZ 85008 ",
    "421 S. Terry Lane, Tempe, AZ 85281",
    "430 N. Avenida Azogue, Tucson AZ 85745",
    "452/456 S. Olive Street, Unit 8, Mesa, AZ 85204",
    "4540+A1:A871 W. Sandhill Road Maricopa, AZ 85139",
    "461 W. Yucca Court, Unit 312, Tucson, AZ 85704",
    "4719 N. 15th Avenue, Unit 2, Phoenix, AZ 85015",
    "4840 E. Abraham Lane, Phoenix, AZ 85054",
    "500 N. Gila Springs Blvd, Unit 134, Chandler, AZ 85226",
    "5120 S. Camellia Drive, Chandler, AZ 85248",
    "535 W. Thomas Road, Apt. 401, Phoenix, AZ 85013",
    "5568 E. Lush Vista View, Florence, AZ 85132",
    "5924 S. Cobalt, Mesa, AZ 85212",
    "5949 E. Thomas Road, Scottsdale, AZ 85251",
    "6112 E. Karen Drive, Scottsdale, AZ 85254",
    "6125 E. Indian School Road, #210, Scottsdale, AZ ",
    "6210 N. 12th Place, Unit 3, Phoenix, AZ 85014 ",
    "6212 N. 12th Place, Phoenix, AZ 85014",
    "6408 S. La Corta Drive, Tempe, AZ 85283",
    "6413 E. Avalon Street, Unit C, Mesa, 85205",
    "6675 E. Ladonna Lane, Tucson, AZ 85756",
    "6692 S. Pepperweed, Drive, Tucson, AZ 85756",
    "6698 E. Summerset Road, Florence AZ 85132",
    "6734 W. Ruth Avenue, Peoria, AZ 85345",
    "6816 E. Kings Avenue, Scottsdale, AZ 85254",
    "6926 W. Via Del Sol Drive, Glendale, AZ 85310",
    "7317 E Gallego Lane, Scottsdale, AZ 85255",
    "7324 W. Forest Grove Avenue, Phoenix, AZ 85043",
    "7501 E. Taylor Street, Scottsdale, AZ 85257",
    "810 N. Granada Drive, Chandler, 85286",
    "8107 W. Earll Drive, Phoenix AZ 85033",
    "8121 E. Via De Viva  Scottsdale AZ 85258",
    "8125 E. Glenrosa Avenue, Scottsdale AZ 85251",
    "822 S. Langley Avenue, Unit 207, Tucson, AZ 85710",
    "8467 S. Otis Drive, Tucson, AZ 85747",
    "8749 E. Belleview Street, Scottsdale, AZ 85257",
    "9270 W. Trotting Wolf Trail Marana, AZ 85623",
    "9331 E. Mark Lane, Scottsdale AZ 85262",
    "9445 N. 94th Place, Unit 206, Scottsdale, AZ 85258",
    "9460 W. Roma Avenue, Phoenix, AZ 85037",
    "969 S. Huish Drive, Gilbert, AZ 85296",
    "972 E. Denim Trail, San Tan Valley, AZ 85143",
    "9855 E. Acacia Drive Scottsdale AZ 85260",
]

const MainStep = ({ propertyName, setActiveStep, setPropertyName, setLoginModal, loginModal, fetchData, openApplicationList, setOpenApplicationList, setApplicationData, propertyListData, setIsAppListOpen }) => {

    const [propertyField, setPropertyField] = useState("")
    const [isUser, setIsUser] = useState(false)
    const [isMain, setIsMain] = useState(true)
    const [applicationList, setApplicationList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState("")

    useEffect(() => {
        if(propertyName) {
            setPropertyField(propertyName)
        }
    }, [])

    useEffect(() => {
        if(openApplicationList) {
            handleApplicationList()
        }
    }, [openApplicationList])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userData"));
        if(user?.id) {
            setIsUser(true)
        } else {
            setIsUser(false)
        }
    }, [loginModal])


    const handleMainStep = () => {
        if(propertyField) {
            setErrors("")
            setPropertyName(propertyField)
            localStorage.setItem("propertyName", propertyField)
            setActiveStep(1)
        } else {
            setErrors("Please select property")
        }
    }

    const handleApplicationList = async () => {
        const user = JSON.parse(localStorage.getItem("userData"));
        setIsMain(false)
        setIsAppListOpen(true)
        setIsLoading(true)
        if(user?.email) {
            await API.get(REACT_APP_API_NAME, `/application/mysavedapplication?userId=${user.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then((response) => {
                    setApplicationList(response)
                    setOpenApplicationList(false)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.log(err)
                })
        }
    }

    const handleDelele = async (selectedRowKeys, recordId) => {
        setIsLoading(true)
        let deleteObj = { ids: [selectedRowKeys], recordIds: recordId ? [recordId] : [] }
        await API.del(REACT_APP_API_NAME, "/application/delete", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: deleteObj
        })
            .then((response) => {
                setApplicationData([])
                handleApplicationList()
                toast.success("Application deleted successfully!")
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.message)
                setIsLoading(false)
            })
    }

    const handleEdit = async (propertyName) => {
        const user = JSON.parse(localStorage.getItem("userData"));
        if(user?.id) {
            setPropertyName(propertyName)
            localStorage.setItem("propertyName", propertyName)
            fetchData(user)
        }
    }

    const handleSelectProperty = (value) => {
        setPropertyField(value)
        if(value) {
            setErrors("")
        } else {
            setErrors("Please select property")
        }
    }

    return (
        <>
            {isMain ? <section className='main-start-step'>
                <div className='main-start-step-wrap'>
                    <div className='position-relative property-banner'>
                        <img src={logo} alt='property' />
                        {/* <a className='property-link poppins'>425 New York Ave, Huntington, NY 11743</a> */}
                    </div>
                    <div className='start-app-content'>
                        <h3 className='step-title text-center mb-2'>Start My Application</h3>
                        {/* <p className='fs-16'>This property is trending 🔥 Apply today so you don't miss this home!</p> */}
                        <ul className='property-list'>
                            <li>
                                <svg color="#04BF8D" width="50" height="50" viewBox="0 0 50 50"><path d="M25 0c13.75 0 25 11.25 25 25S38.75 50 25 50 0 38.75 0 25 11.25 0 25 0Zm12 15L20.714 29.615 13 22.692l-3 2.693L20.714 35 40 17.692 37 15Z" fill="#04BF8D" fillRule="evenodd"></path></svg>
                                <span className='fs-16 d-block'>Takes just a few minutes to complete</span>
                            </li>
                            <li>
                                <svg color="#04BF8D" width="50" height="50" viewBox="0 0 50 50"><path d="M25 0c13.75 0 25 11.25 25 25S38.75 50 25 50 0 38.75 0 25 11.25 0 25 0Zm12 15L20.714 29.615 13 22.692l-3 2.693L20.714 35 40 17.692 37 15Z" fill="#04BF8D" fillRule="evenodd"></path></svg>
                                <span className='fs-16 d-block'>Go at your own pace—easy to save and resume at any time</span>
                            </li>
                            <li>
                                <svg color="#04BF8D" width="50" height="50" viewBox="0 0 50 50"><path d="M25 0c13.75 0 25 11.25 25 25S38.75 50 25 50 0 38.75 0 25 11.25 0 25 0Zm12 15L20.714 29.615 13 22.692l-3 2.693L20.714 35 40 17.692 37 15Z" fill="#04BF8D" fillRule="evenodd"></path></svg>
                                <span className='fs-16 d-block'>Will never impact your credit score</span>
                            </li>
                        </ul>
                        <Form.Group as={Col} className='mb-2 mt-4'>
                            <Form.Label>Select Property</Form.Label>
                            <Form.Select
                                value={propertyField}
                                onChange={(e) => handleSelectProperty(e.target.value)}
                                isInvalid={errors}
                            >
                                <option value="">Select Property</option>
                                {
                                    propertyListData?.map((item, i) => {
                                        return (
                                            <option key={i} value={item}>{item}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='start-step-bottom'>
                        <button type='button' className='text-uppercase step-button start-step-btn d-block mx-auto mb-4' onClick={handleMainStep}>START MY APPLICATION</button>
                        {isUser && <button type='button' className='text-uppercase step-button start-step-btn d-block mx-auto mb-4 mt-2' onClick={handleApplicationList}>APPLICATIONS LIST</button>}
                        <p className='text-center fs-16 mb-0'>Already started one? <a onClick={() => setLoginModal(true)} className='text-decoration-underline link-text'>Finish your application here.</a></p>
                    </div>
                </div>
            </section>
                :
                <section className='main-start-step'>
                    <div className='p-3'>
                        <button type='button'
                            onClick={() => {
                                setIsMain(true)
                                setIsAppListOpen(false)
                            }}
                            className='main-back-btn mb-3 d-flex align-items-center'>
                            <svg width="18" height="18" color="#033A6D" viewBox="0 0 18 18" fill="#033A6D"><path d="M17.25 8.25H2.678l7.579-6.948A.75.75 0 009.243.197l-9 8.25-.01.012c-.023.02-.041.044-.06.067-.013.016-.027.03-.038.046-.02.029-.035.06-.051.09-.008.013-.016.026-.022.04a.715.715 0 00-.039.12c-.001.007-.006.016-.007.024a.798.798 0 000 .307c.001.01.005.017.007.024.01.042.022.083.04.12.005.015.013.027.021.04.016.032.031.062.051.09.01.017.025.03.037.046.02.023.038.046.06.067l.011.012 9 8.25a.748.748 0 001.06-.045.75.75 0 00-.046-1.06L2.678 9.75H17.25a.75.75 0 100-1.5"></path></svg>
                            <span className='d-block ms-1'>BACK</span>
                        </button>
                        <h3 className='step-title mb-3'>Applications List</h3>
                        <div className='application-list-table'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Property Name</th>
                                        <th>Status</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading ?
                                        applicationList?.length !== 0 ?
                                            applicationList.map((item, i) => {
                                                let status = (item?.activeStep) * 10;
                                                let variant = "danger";
                                                if(status <= 25) {
                                                    variant = "danger"
                                                } else if(status <= 50) {
                                                    variant = "warning"
                                                } else if(status <= 80) {
                                                    variant = "info"
                                                } else if(status === 100 || item.isCompleted) {
                                                    variant = "success"
                                                } else {
                                                    variant = "info"
                                                }
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.about.firstName}</td>
                                                        <td>{item.propertyName}</td>
                                                        <td style={{ width: "120px" }}>
                                                            {status ? <ProgressBar variant={variant} now={item.isCompleted ? 100 : status} label={`${item.isCompleted ? 100 : status}%`} /> : "No"}
                                                        </td>
                                                        <td>
                                                            <DropdownButton
                                                                key={i}
                                                                className='dropdown-button-main'
                                                                id={`dropdown-variants-${i}`}
                                                                drop='start'
                                                                title={<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 12H12.01M12 6H12.01M12 18H12.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18ZM13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>}
                                                            >
                                                                {!item.isCompleted && <span className="dropdown-item action-btn mb-1" onClick={() => handleEdit(item.propertyName)}><svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>Edit</span>}
                                                                <span className="dropdown-item action-btn" onClick={() => handleDelele(item.id, item.recordId)}><svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>Delete</span>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={4} className='text-center'>
                                                    No applications found
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan={4}>
                                                <LoaderScreen />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default MainStep