import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import Loader from './Loader';

const StepTwo = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader, applicationData } = props

    let email_pattern = /^([a-zA-Z0-9_\.\-+])+\@(?!.*\.{2})[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,})$/;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isCoSignerSubmitted, setIsCoSignerSubmitted] = useState(false);
    const [coSigner, setCoSigner] = useState({
        fullName: "",
        email: "",
        relationship: "",
        dateOfBirth: "",
    })
    const [addCoSigner, setAddCoSigner] = useState(false);
    const [editCoSigner, setEditCoSigner] = useState(false);
    const [data, setData] = useState({
        moveInDate: "",
        coSigners: [],
    });
    const [errors, setErrors] = useState({
        moveInDate: "",
        coSigners: "",
    });
    const [coSignerId, setCoSignerId] = useState('')
    const [isAnyError, setIsAnyError] = useState({value: false, isCheck: false})

    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
    }, [])

    useEffect(() => {
        if(isAnyError.isCheck) {
            if(
                !data.moveInDate || data.coSigners.length === 0
            ) setIsAnyError({value: true, isCheck: true})
            else setIsAnyError({value: false, isCheck: true})
        }
    }, [data])
    

    useEffect(() => {
        if(!activeData) {
            if(data?.coSigners?.length === 0 && !editCoSigner) {
                if(applicationData?.length) {
                    let defaultData = {
                        fullName: `${applicationData[0]?.stepData?.firstName} ${applicationData[0]?.stepData?.lastName}`,
                        email: applicationData[0]?.stepData?.email,
                        relationship: applicationData[0]?.stepData?.relationship,
                        dateOfBirth: applicationData[0]?.stepData?.dateOfBirth,
                    }
                    setData({
                       ...data,
                       coSigners: [ ...data.coSigners, defaultData]
                    })
                }
            }
        }
        
    }, [editCoSigner])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const getData = () => {
        let dataObj = {
            step: 2,
            stepData: data,
        }
        return dataObj;
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        let error = { ...errors }
        if(isSubmitted) {
            // if(name === "agentName") {
            //     if(!value) {
            //         error = { ...error, agentName: "Agent name is required" }
            //     } else {
            //         error = { ...error, agentName: "" }
            //     }
            // }
            // if(name === "agentEmail") {
            //     if(!value) {
            //         error = { ...error, agentEmail: "Agent email is required" }
            //     } else {
            //         error = { ...error, agentEmail: "" }
            //     }
            // }
            if(name === "moveInDate") {
                if(!value) {
                    error = { ...error, moveInDate: "move-in date is required" }
                } else {
                    error = { ...error, moveInDate: "" }
                }
            }
            if(name === "password") {
                if(!value) {
                    error = { ...error, password: "password is required" }
                } else if(value.length < 8) {
                    error = { ...error, password: "minimum 8 charcters required" }
                } else {
                    error = { ...error, password: "" }
                }
            }
            if(name === "firstName") {
                if(!value) {
                    error = { ...error, firstName: "firstname is required" }
                } else {
                    error = { ...error, firstName: "" }
                }
            }
            if(name === "lastName") {
                if(!value) {
                    error = { ...error, lastName: "lastname is required" }
                } else {
                    error = { ...error, lastName: "" }
                }
            }
        }
        setErrors(error)
        setData({ ...data, [name]: value })
    }

    const handleNext = async (e) => {
        e.preventDefault()
        setIsSubmitted(true)
        let error = {};
        let isError = false;
        // if(applicationData[0]?.stepData?.realtor_worker === "Yes") {
        //     if(!data.agentName) {
        //         error = { ...error, agentName: "Agent name is required" }
        //         isError = true
        //     }
        //     if(!data.agentEmail) {
        //         error = { ...error, agentEmail: "Agent email is required" }
        //         isError = true
        //     }
        // }
        if(!data.moveInDate) {
            error = { ...error, moveInDate: "move-in date is required" }
            isError = true
        }
        if(data.coSigners.length === 0) {
            error = { ...error, coSigners: "Add a person living in the property" }
            isError = true
        }
        if(isError) {
            setErrors(error)
            setIsAnyError({value: true, isCheck: true})
            return
        }
        await setMainData(2, data)
        setActiveStep(3)
    }

    const handleCoSignerSave = () => {
        setIsCoSignerSubmitted(true)
        if(!coSigner.fullName || (coSigner.email && !email_pattern.test(coSigner.email)) || !coSigner.relationship || !coSigner.dateOfBirth) {
            return
        } else {
            setErrors({ ...errors, coSigners: "" })
        }

        if(editCoSigner) {
            setData(prevData => {
                const newCoSigners = [...prevData.coSigners];
                newCoSigners[coSignerId] = coSigner;
                return { ...prevData, coSigners: newCoSigners };
            });
        } else {

            setData({ ...data, coSigners: [...data.coSigners, coSigner] })
        }

        setCoSigner({
            fullName: "",
            email: "",
            relationship: "",
            dateOfBirth: "",
        })
        setAddCoSigner(false)
        setEditCoSigner(false)
        setIsCoSignerSubmitted(false)
    }

    const handleEdit = (item, index) => {
        const newCoSigners = data.coSigners.map((coSigner, i) =>
            i === index ? {
                "fullName": null,
                "email": null,
                "relationship": null,
                "dateOfBirth": null,
            } : coSigner
        );
        setData({
            ...data,
            coSigners: newCoSigners
        });
        setCoSignerId(index)
        setCoSigner(item)
        setAddCoSigner(false)
        setEditCoSigner(true)
    }

    const handleDelete = (index) => {
        if(data.coSigners.length === 1) {
            setAddCoSigner(true)
        }
        setData({ ...data, coSigners: data.coSigners.filter((item, i) => index !== i) })
    }

    return (
        <>
            <section className='step-section step-2'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-3'>Application Info</h3>
                <Form onSubmit={handleNext}>
                    {/* {applicationData[0]?.stepData?.realtor_worker === "Yes" ?
                        <>
                            <Form.Group as={Col} md="8" className='mb-3' controlId="agentName">
                                <Form.Label>Agent name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="agentName"
                                    value={data.agentName}
                                    onChange={handleChange}
                                    isInvalid={errors.agentName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.agentName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="8" className='mb-3' controlId="agentEmail">
                                <Form.Label>Agent email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="agentEmail"
                                    value={data.agentEmail}
                                    onChange={handleChange}
                                    isInvalid={errors.agentEmail}
                                />
                                <Form.Control.Feedback type="invalid">{errors.agentEmail}</Form.Control.Feedback>
                            </Form.Group>
                        </>
                        : ''
                    } */}
                    <Form.Group as={Col} md="6" className='mb-3' controlId="phoneNumber">
                        <Form.Label>Desired Move-In Date</Form.Label>
                        <DatePicker
                            minDate={new Date()}
                            value={data.moveInDate}
                            onChange={newDate => {
                                handleChange({ target: { name: 'moveInDate', value: newDate } });
                            }}
                            className={errors.moveInDate ? "date-picker-error" : ""}
                        />
                        {errors.moveInDate && <div className="text-danger error-msg">{errors.moveInDate}</div>}
                    </Form.Group>
                    <h6 className='mt-4 mb-1 question'>List all people that will be living in the property.</h6>
                    {
                        // !addCoSigner && !editCoSigner &&
                        <button onClick={() => setAddCoSigner(true)} type='button' className='action-btn'>
                            <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                            Add Person
                        </button>
                    }
                    {
                        // addCoSigner &&
                        <>
                            {
                                data.coSigners.map((item, index) =>
                                    <React.Fragment key={index}>
                                        {item.fullName !== null ? <div className='applying-item'>
                                            <div>
                                                <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                                    <div className='application-name'>Occupant {index + 1}</div>
                                                    {/* <div className='application-not-text'>
                                                        <svg width="48" height="48" color="#042238" viewBox="0 0 48 48" fill="#042238"><path d="M24 .667C36.886.667 47.333 11.113 47.333 24c0 12.884-10.449 23.333-23.333 23.333C11.113 47.333.667 36.886.667 24 .667 11.112 11.112.667 24 .667zm0 2.029C12.232 2.696 2.696 12.232 2.696 24c0 11.765 9.538 21.304 21.304 21.304 11.764 0 21.304-9.54 21.304-21.304 0-11.766-9.539-21.304-21.304-21.304zm-8.032 11.863l.14.117 7.89 7.889 7.892-7.89a1.014 1.014 0 011.552 1.295l-.117.14L25.434 24l7.89 7.89a1.014 1.014 0 01-1.293 1.552l-.141-.117-7.891-7.89-7.89 7.889a1.014 1.014 0 01-1.552-1.294l.117-.14 7.89-7.89-7.89-7.89a1.014 1.014 0 011.294-1.551z"></path></svg>
                                                        <div>Application Not Submitted Yet</div>
                                                    </div> */}
                                                </h4>
                                                <p className="mb-1"><span className="box-sub-title">Full Name: </span>{item.fullName}</p>
                                                <p className="mb-1"><span className="box-sub-title">Email: </span>{item.email}</p>
                                                <p className="mb-1"><span className="box-sub-title">Relationship: </span>{item.relationship}</p>
                                                {index===0 ? <p className="mb-1"><span className="box-sub-title">SSN: </span>{applicationData[0]?.stepData?.ssn}</p>:''}
                                                {index===0 ? <p className="mb-1"><span className="box-sub-title">Driver's license / State ID: </span>{applicationData[0]?.stepData?.drivers_license}</p> : ''}
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <button onClick={() => handleEdit(item, index)} type='button' className='action-btn'>
                                                        <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                                        Edit
                                                    </button>
                                                    <button onClick={() => handleDelete(index)} type='button' className='action-btn'>
                                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    </React.Fragment>
                                )
                            }
                            {
                                (addCoSigner || editCoSigner) &&
                                <div className='applying-form position-relative'>
                                    {
                                        data.coSigners.length !== 0 &&
                                        <button onClick={() => { setAddCoSigner(false); setCoSigner({ fullName: "", email: "", relationship: "", dateOfBirth: ""}) }} type='button' className='action-btn delete-form-btn'>
                                            <svg className='m-0' width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        </button>
                                    }
                                    <p className="mb-2 info-box-title">Occupant {!editCoSigner ? data?.coSigners?.length + 1 : coSignerId + 1}</p>
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-3' controlId="firstName">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fullName"
                                                value={coSigner.fullName}
                                                onChange={(e) => setCoSigner({ ...coSigner, fullName: e.target.value })}
                                                isInvalid={!coSigner.fullName && isCoSignerSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">full name is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" className='mb-3' controlId="firstName">
                                            <Form.Label>Email Address <span style={{ opacity: '0.8' }}>(Optional)</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="emailId"
                                                value={coSigner.email}
                                                onChange={(e) => setCoSigner({ ...coSigner, email: e.target.value })}
                                                isInvalid={(coSigner.email && !email_pattern.test(coSigner.email)) && isCoSignerSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">email is invaild</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3 col-md-6' controlId="relationship">
                                            <Form.Label>Relationship</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="relationship"
                                                value={coSigner.relationship}
                                                onChange={(e) => setCoSigner({ ...coSigner, relationship: e.target.value })}
                                                isInvalid={!coSigner.relationship && isCoSignerSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid" className="text-danger error-msg">Relationship is required</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-3' controlId="dateOfBirth">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <DatePicker
                                                value={coSigner.dateOfBirth}
                                                onChange={newDate => {
                                                    setCoSigner({ ...coSigner, dateOfBirth: newDate });
                                                }}
                                                maxDate={new Date()}
                                                className={!coSigner.dateOfBirth && isCoSignerSubmitted ? "date-picker-error" : ""}
                                            />
                                            {!coSigner.dateOfBirth && isCoSignerSubmitted && <div className="text-danger error-msg">Date of birth is required</div>}
                                        </Form.Group>
                                    </Row>
                                    <Button onClick={handleCoSignerSave} type="button" className='text-uppercase border-btn d-block mx-auto mt-2'>Save</Button>
                                </div>
                            }


                            {errors.coSigners && <div className="text-danger error-msg">{errors.coSigners}</div>}
                        </>
                    }

                    <Button type="submit" disabled={isMainLoader} className='text-uppercase step-button d-block mx-auto mt-3'>{isMainLoader ? <Loader /> : 'Next'}</Button>
                    {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                </Form>
            </section >
        </>
    );
})

export default StepTwo;