import productBucketAWS from ".";

export const DeleteFunction = async (file) => {

    return new Promise(async (resolve, reject) => {
        const params = {
            Key: file,
        }
        try {
            let result = await productBucketAWS.deleteObject(params).promise()
            if(result.$response.httpResponse.statusCode === 204) {
                resolve('Image Deleted')
            } else {
                console.log('Error in Image Delete')
                console.log(result)
                reject('Error in Image Delete')
            }
        } catch(error) {
            console.log(error)
            reject('Something bad happen')
        }
    })
}